angular.module("WeatherApp").factory("PWSFactory", ["$resource", function ($resource) {
    return $resource('/api/v2.0/weather/gateway/', {}, {

        save: {
            method: 'POST'
        },
        get: {
            params: {gateway_slug: '@gateway_slug'},
            url: '/api/v2.0/weather/gateway/:gateway_slug/',
            method: 'GET'
        },
        query: {
            params: {data_format: '@data_format'},
            method: 'GET'
        },
        update: {
            params: {gateway_slug: '@gateway_slug'},
            url: '/api/v2.0/weather/gateway/:gateway_slug/',
            method: 'PUT'
        }

    });
}]);