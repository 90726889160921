angular.module("WeatherApp").controller('PWSDetailChartTableController',
    function ($scope, $rootScope, $resource, $mdDialog, $khMessage, $khPulse, WeatherUtilsService) {

        defineScopeFunctions();

        defineScopeVariables();

        loadInitialData();

        function loadInitialData() {
            emptyCurrentData();
            setDefaultFetchDates();
            getStationData();
        }

        function setNodeDropDowns() {
            $scope.showNodeDropDown = false;
            if ($rootScope.gateway.properties.nodes.length === 1) {

                $scope.selectedNode = $rootScope.gateway.properties.nodes[0].slug;

            } else {
                $scope.showNodeDropDown = true;
                $scope.selectedNode = $rootScope.gateway.properties.nodes[0].slug;
                $scope.nodeDropDownValues = buildAvailableNodeDropDown();
            }
        }

        function buildAvailableNodeDropDown() {
            var nodeDropDown = [];
            angular.forEach($rootScope.gateway.properties.nodes, function (node, index) {
                nodeDropDown.push({
                    'name': node.node_id,
                    'value': node.slug
                })
            });
            return nodeDropDown
        }

        function setDefaultFetchDates() {
            if (!$scope.dataFrequency) {
                $scope.dataFrequency = "daily"
            }
            $scope.maxStartDate = moment(new Date()).toDate();
            if ($rootScope.gateway.properties.data_start_date) {
                $scope.minStartDate = moment($rootScope.gateway.properties.data_start_date, 'YYYY-MM-DD').toDate();
            } else {
                $scope.minStartDate = moment(new Date()).subtract(3, "years").toDate();
            }
            var timeDurationParams = getTimeDurationParams();
            var startDate = moment(new Date()).subtract(timeDurationParams[1], timeDurationParams[0]).toDate();
            if (moment($scope.minStartDate).isAfter(moment(startDate))) {
                $scope.updateFetchDates($scope.minStartDate)
            } else {
                $scope.updateFetchDates(startDate)
            }
        }

        function defineScopeFunctions() {
            $scope.setCurrentItem = function (itemId) {
                if (itemId === $scope.currentItem) {
                    $scope.currentItem = null;
                } else {
                    $scope.currentItem = itemId;
                }
            };

            $scope.updateNodeSelection = function (node_slug) {
                $scope.selectedNode = node_slug;
                availableParametersDropDown();
            };

            $scope.updateFrequency = function (dataFrequency) {
                /*
                 Dates needs updates as they have different duration( instantaneous has 7 days , daily has 6 months)
                 Available drop down needs update because daily and instantaneous params are different
                 */

                $scope.dataFrequency = dataFrequency;
                var today = moment();
                var timeDurationParams = getTimeDurationParams();
                var startDate = moment(angular.copy($scope.dataFetchEndDate)).subtract(timeDurationParams[1], timeDurationParams[0]);
                if (startDate.isAfter(today)) {
                    $scope.dataFetchStartDate = today.toDate();
                } else {
                    $scope.dataFetchStartDate = startDate.toDate();
                }
                availableParametersDropDown();
            };

            $scope.updateFetchDates = function (startDate) {
                /*
                 Updates start date and end dates
                 End date is addition of 15 days to chosen start date
                 */
                var timeDurationParams = getTimeDurationParams();
                var endDate = moment(startDate).add(timeDurationParams[1], timeDurationParams[0]);
                var today = moment();
                if (endDate.isAfter(today)) {
                    $scope.dataFetchEndDate = today.toDate();
                } else {
                    $scope.dataFetchEndDate = endDate.toDate();
                }
                $scope.dataFetchStartDate = startDate;
            };

            $scope.changeDataView = function (selectedDataView) {
                $scope.currentDataView = selectedDataView;
            };

            $scope.changeParamOnClick = function (selectedParams) {
                /*
                 Removes wind direction from selected params as only Wind terminology is being used for both wind speed and direction
                 */
                $scope.selected_parameters = selectedParams;
                var indexOfWindSpeed = $scope.selected_parameters.indexOf('wind_speed');
                if (indexOfWindSpeed !== -1) {
                    if ($scope.selected_parameters.indexOf('wind_direction') === -1) {
                        $scope.selected_parameters.splice(indexOfWindSpeed + 1, 0, 'wind_direction')
                    }
                } else {
                    WeatherUtilsService.removeElementFromArray($scope.selected_parameters, 'wind_direction');
                }

                var indexOfMeanWindSpeed = $scope.selected_parameters.indexOf('mean_wind_speed');
                if (indexOfMeanWindSpeed !== -1) {
                    if ($scope.selected_parameters.indexOf('mode_wind_direction') === -1) {
                        $scope.selected_parameters.splice(indexOfWindSpeed + 1, 0, 'mode_wind_direction')
                    }
                } else {
                    WeatherUtilsService.removeElementFromArray($scope.selected_parameters, 'mode_wind_direction');
                }
                emptyCurrentData();
                getStationData();
            };

            $scope.OpenStationExportDialog = function (gateway_slug) {
                var dataFromParentController = {};
                dataFromParentController["selected_parameters"] = $scope.selected_parameters;
                dataFromParentController["availableParams"] = $scope.availableParams;
                dataFromParentController["data_frequency"] = $scope.dataFrequency;
                dataFromParentController["slug"] = gateway_slug;
                dataFromParentController["node_slug"] = $scope.selectedNode;
                dataFromParentController["start_date"] = $scope.dataFetchStartDate;
                dataFromParentController["end_date"] = $scope.dataFetchEndDate;
                $mdDialog.show({
                    controller: "PWSDataExportController",
                    skipHide: true,
                    multiple: true,
                    templateUrl: '/static/assets/khapps/weather/partials/abstract/WeatherDataExport.html',
                    parent: angular.element(document.body),
                    locals: {
                        dataFromParentController: dataFromParentController
                    },
                    clickOutsideToClose: false
                })
            };

        }

        function getTimeDurationParams() {
            /*
             Sets diff between start end end date based on data frequency
             */
            if ($scope.dataFrequency === "daily") {
                return ["months", 6]
            } else {
                return ["days", 7]
            }

        }

        function defineScopeVariables() {
            $scope.showDateFilters = true;
            $scope.showDataFrequencyFilters = true;
            $scope.NodeParamMap = buildNodeParamMap();
            setNodeDropDowns();
            $scope.availableFrequencies = [
                {"name": "Daily", "value": "daily"},
                {"name": "Intraday", "value": "instantaneous"}
            ];
            $scope.dataFrequency = "daily";
            $scope.parametersData = {
                selected_parameters: []
            };
            availableParametersDropDown();
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.chartConfiguration.plotOptions = {series: {connectNulls: true}};
            $scope.chartConfiguration.series = [];
            $scope.currentItem = 0;
            $scope.selected_parameters = [];
            $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
            $scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();
            $scope.currentDataView = 'chartView';
            $scope.currentChartTableData = null;
            $scope.enableStationWeatherDataExportButton = true;
            $scope.userSettingDateTimeFormat = $rootScope.userSettingDateTimeFormat;
        }

        function getStationData() {
            $khPulse.show();
            $scope.chartConfiguration.lang.noData = WeatherUtilsService.MessageWeatherChartDataLoading;
            $resource('/api/weather/sensor-data/').get(getApiQueryParamDict(), function (apiData) {
                if (apiData.data && apiData.data.length > 0) {
                    $scope.currentChartTableData = apiData.data;
                    updateDataFormat(angular.copy($scope.dataFrequency));
                    buildDataView();
                }
                else {
                    emptyCurrentData();
                    $scope.chartConfiguration = false;
                }
                $khPulse.hide();
            }, function (error) {
                emptyCurrentData();
                $scope.chartConfiguration = false;
                $khPulse.hide();
            })
        }

        function getApiQueryParamDict() {
            if ($scope.selected_parameters.length === 0) {
                if ($scope.dataFrequency === "instantaneous") {
                    if ($scope.NodeParamMap[$scope.selectedNode].instantaneous && ($scope.NodeParamMap[$scope.selectedNode].parameters.instantaneous.length > 2)) {
                        $scope.selected_parameters = angular.copy($scope.NodeParamMap[$scope.selectedNode].parameters.instantaneous).slice(0, 3);

                    }
                    else {
                        $scope.selected_parameters = angular.copy($scope.NodeParamMap[$scope.selectedNode].parameters.instantaneous);
                    }
                } else {
                    if ($scope.NodeParamMap[$scope.selectedNode].parameters.daily && ($scope.NodeParamMap[$scope.selectedNode].parameters.daily.length > 2)) {
                        $scope.selected_parameters = angular.copy($scope.NodeParamMap[$scope.selectedNode].parameters.daily).slice(0, 3);

                    }
                    else {
                        $scope.selected_parameters = angular.copy($scope.NodeParamMap[$scope.selectedNode].parameters.daily);
                    }
                }
            }

            var queryParamDict = {
                parameters: String($scope.selected_parameters),
                gateway_slug: $rootScope.gateway.properties.slug,
                node_slug: $scope.selectedNode,
                localize: "1"
            };

            if ($scope.dataFrequency === "instantaneous") {
                queryParamDict['frequency'] = 'instantaneous'

            } else {
                queryParamDict['frequency'] = 'daily';
                queryParamDict['params_as_daily'] = '1';
            }
            return angular.extend(queryParamDict, getFetchingDateDict())
        }

        function getFetchingDateDict() {
            return {
                start_date: WeatherUtilsService.getDbDate($scope.dataFetchStartDate),
                end_date: WeatherUtilsService.getDbDate($scope.dataFetchEndDate)
            }
        }
        
        function availableParametersDropDown() {
            var array = null;
            if ($scope.dataFrequency === "instantaneous") {
                array = angular.copy($scope.NodeParamMap[$scope.selectedNode].parameters.instantaneous);
            } else {
                array = angular.copy($scope.NodeParamMap[$scope.selectedNode].parameters.daily);
            }
            $scope.availableParams = WeatherUtilsService.getParametersNamevalueDictList(array);
            $scope.selected_parameters = angular.copy(array).slice(0, 3);
            $scope.parametersData.selected_parameters = angular.copy(array).slice(0, 3);
            return array
        }

        function buildNodeParamMap() {
            var nodeParamMap= {};
            angular.forEach($rootScope.gateway.properties.nodes, function (node, index) {
                nodeParamMap[node.slug] = node
            });

            return nodeParamMap
        }

        function updateDataFormat(dataType) {
            $scope.tableDataDateType = dataType;

            if (dataType === 'daily') {
                $scope.xAxisFormat = {
                    type: 'datetime',
                    labels: {
                        format: '{value: %e %b, %Y}'
                    }
                };
            }
            else {
                $scope.xAxisFormat = {
                    type: 'datetime'
                };
            }
        }

        function buildDataView() {
            buildChart();
            buildTable();
        }

        function removeNonRequiredParamsFromChartParams(chartParams) {
            chartParams = WeatherUtilsService.removeElementFromArray(chartParams, 'wind_direction');
            chartParams = WeatherUtilsService.removeElementFromArray(chartParams, 'mode_wind_direction');
            return chartParams
        }

        function buildChart() {
            var chartParams = angular.copy($scope.selected_parameters);
            chartParams = removeNonRequiredParamsFromChartParams(chartParams);
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.chartConfiguration.plotOptions = {series: {connectNulls: true}};
            $scope.chartConfiguration.series = [];
            WeatherUtilsService.getChartConfig($scope, chartParams, $scope.xAxisFormat);

            var extraChartSettings = {
                'conversionRequired': true,
                'conversionMethod': parseInt,
                'xyValuePush': true,
                'includeWindDirection': true
            };
            WeatherUtilsService.pushListDataInChart($scope, $scope.currentChartTableData, chartParams, extraChartSettings);
        }

        function buildTable() {
            $scope.tableData = $scope.currentChartTableData;
        }

        function emptyCurrentData() {
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.chartConfiguration.series = [];
            $scope.currentChartTableData = null;
            $scope.tableData = [];
        }


    });