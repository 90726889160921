angular.module("WeatherApp").directive('blacklist', function (){
   return {
      require: 'ngModel',
      link: function(scope, elem, attr, ngModel) {
          var blacklist = attr.blacklist.split(',');
          for(var i=0; i<blacklist.length; i++){
              blacklist[i] = blacklist[i].toLowerCase();
          }
          ngModel.$parsers.unshift(function (value) {
             ngModel.$setValidity('blacklist', blacklist.indexOf(value.toLowerCase()) === -1);
             return value;
          });
      }
   };
});