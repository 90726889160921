angular.module("WeatherApp").controller('WeatherOverrideController',
    function ($scope, $rootScope, WeatherOverrideRecordFactory, NgTableParams, FieldNamesFactory, $mdDialog,
              $khMessage, FarmFiltersService, WeatherUtilsService) {

        $rootScope.currentWeatherOverrideView = "Override";
        $scope.isEmptyTableDataMessage = false;
        setUpWeatherOverideSecondaryNavBarParameters();

        var on_override_save = $rootScope.$on("saved_weather_override", function () {
            loadWeatherOverrideContent();
        });

        var on_override_delete = $rootScope.$on("deleted_weather_override", function () {
            loadWeatherOverrideContent();
        });

        var on_field_change = $scope.$on("filter:selections_updated",
            function () {
                if ($rootScope.currentWeatherOverrideView == 'Override') {
                    FarmFiltersService.setDefaultFieldFarm();
                    debounce_loadWeatherOverrideContent();
                }
            });

        $scope.$on('$destroy', function () {
            on_override_save();
            on_field_change();
            on_override_delete();
        });

        function query_param_dictionary() {
            var queryParamDict = {};
            if (FarmFiltersService.filter.farm_slug && FarmFiltersService.filter.farm_slug !== 'All Farms') {
                queryParamDict['farm_slug'] = FarmFiltersService.filter.farm_slug;
            }
            if (FarmFiltersService.filter.field_slug && FarmFiltersService.filter.field_slug !== 'All Fields') {
                queryParamDict['field_slug'] = FarmFiltersService.filter.field_slug;
            }
            return queryParamDict;

        }


        $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
        $scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();

        $rootScope.addWeatherOverride = function () {

            $mdDialog.show({
                controller: "AddEditWeatherOverrideController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/forms/weatheroverride/AddWeatherOverrideForm.html',
                parent: angular.element(document.body),
                locals: {
                    editWeatherOverrideRecordData: null
                },
                clickOutsideToClose: false,
                escapeToClose: true
            });

        };

        $rootScope.editWeatherOverride = function (recordDataRow) {
            var editWeatherOverrideRecordData = {
                'parameter': 'precipitation',
                'selected_farm': FarmFiltersService.getSelectedFarmSlug(),
                'selected_field': FarmFiltersService.getSelectedFieldSlug(),
                'selected_days': 1,
                'startDate': moment(new Date(recordDataRow.datetime)).toDate(),
                'endDate': moment(new Date(recordDataRow.datetime)).toDate(),
                parameter_value: recordDataRow.value

            };
            $mdDialog.show({
                controller: "AddEditWeatherOverrideController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/forms/weatheroverride/EditWeatherOverrideForm.html',
                parent: angular.element(document.body),
                locals: {
                    editWeatherOverrideRecordData: editWeatherOverrideRecordData
                },
                clickOutsideToClose: false,
                escapeToClose: true
            });
        };


        $scope.deleteWeatherOverride = function (recordDataRow) {
            var message = WeatherUtilsService.getUserSettingsDateFormat($rootScope, moment(new Date(recordDataRow.datetime))) + ' | ' +
                'Field: ' + FarmFiltersService.getSelectedField().name + ' | ' +
                'value: ' + recordDataRow.value + ' mm';

            var confirm = $mdDialog.confirm({
                onComplete: function afterShowAnimation() {
                    var $dialog = angular.element(document.querySelector('md-dialog'));
                    var $actionsSection = $dialog.find('md-dialog-actions');
                    var $confirmButton = $actionsSection.children()[1];
                    angular.element($confirmButton).addClass('md-raised md-warn');
                }
            })
                .title('Are you sure you want to delete the override operation?')
                .textContent(message)
                .targetEvent(recordDataRow)
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {

                var deleteWeatherOverrideRecordData = {
                    weather_param: 'precipitation',
                    farmfield: FarmFiltersService.getSelectedFieldSlug(),
                    number_of_days: parseInt(1),
                    record_date: WeatherUtilsService.getDbDate(recordDataRow.datetime),
                    value: String(recordDataRow.value)

                };


                WeatherOverrideRecordFactory.remove({param: deleteWeatherOverrideRecordData}, function (success_data) {

                    $rootScope.$broadcast('deleted_weather_override');
                    $khMessage.show("Override Operation successfully deleted", 5000);
                    $mdDialog.hide();

                }, function (error) {
                    if (error.status) {
                        $khMessage.show("Failed to delete override operation", 5000);
                    }
                });

            }, function () {
                $khMessage.show("Failed to delete override operation", 5000);
            });
        };

        $scope.weatherOverrideCards = [
            {
                kh_card_content_template: '/static/assets/khapps/weather/partials/weatheroverride/WeatherOverrideContent.html',
                card_flex: 100,
                show_progress_bar: true
            }];

        function loadWeatherOverrideContent() {
            var query_parameters = query_param_dictionary();
            if (query_parameters.farm_slug && query_parameters.field_slug) {
                WeatherOverrideRecordFactory.query(query_parameters, function (apiData) {

                    getNgTable(apiData);
                    if (apiData.length === 0) {
                        $khMessage.show("No record found", 5000);
                    }
                });
            }
            else {
                getNgTable([]);
            }
        }

        var debounce_loadWeatherOverrideContent = _.debounce(loadWeatherOverrideContent, 400);

        function getNgTable(data) {
            $scope.weatherOverrideTableParameters = new NgTableParams({sorting:{datetime:"desc"}}, {dataset: data});

            $scope.weatherOverrideTableParameters = new NgTableParams({sorting: {datetime: "desc"}}, {dataset: data});
            $scope.isEmptyTableDataMessage = data.length === 0;
        }

        $scope.searchFarm = {
            "farm.name": {
                id: 'text',
                placeholder: 'Filter by farm name'
            }
        };
        $scope.searchField = {
            "field.name": {
                id: 'text',
                placeholder: 'Filter by field name'
            }
        };
        $scope.searchDate = {
            datetime: {
                id: 'text',
                placeholder: 'Filter by date'
            }
        };
        $scope.searchValue = {
            value: {
                id: 'text',
                placeholder: 'Filter by value'
            }
        };

        function setUpWeatherOverideSecondaryNavBarParameters() {
            $rootScope.show_region = false;
            $rootScope.show_options = false;
            $rootScope.showFarmDropDown = true;
            $rootScope.showFieldDropDown = true;
            $rootScope.showPWSDropDown = false;

            $rootScope.pageTitle = "AgroMet";
            $rootScope.page_id = "weather";
            $rootScope.sub_app_page_id = "weather_override";
            $rootScope.showDataLoggerSubLinks = false;
            $rootScope.show_override = true;
            $rootScope.showWeatherLandingCardViewSubLinks = false;
            $rootScope.showSensorListMainCardViewSubLinks = false;

        }


    });
