angular.module('WeatherApp')
    .controller('WeatherActualDataExportController', WeatherActualDataExportController);

function WeatherActualDataExportController($scope, $rootScope, $resource, paramsData, FarmNamesFactory, FieldNamesFactory, $mdDialog, $khMessage, WeatherUtilsService) {

    buildSelectedParams();
    setUpExportForm();

    function setUpExportForm() {
        FarmNamesFactory.query({}, function (apiData) {
            $scope.farmOptions = apiData;
            defineExportWeatherDataFormConfiguration();
        });
    }

    var exportDailyParams = ["total_precipitation", "mean_temperature", 'min_temperature', 'max_temperature', "mean_wind_speed", "mean_relative_humidity", "mean_pressure", "mode_wind_direction", "ref_et_penman"];
    var exportHourlyParams = ["precipitation", "temperature", "wind_speed", "wind_direction", "relative_humidity", "pressure"];

    $scope.exportDataQueryModel = {
        queryParams: paramsData.selectedParams,
        selected_farm: $rootScope.selected_farm.value,
        selected_field: $rootScope.selected_field.value,
        frequency: paramsData.dataFrequency
    };

    function buildSelectedParams() {
        if (!paramsData.selectedParams) {

            // Default selected params for export
            if (paramsData.dataFrequency === "daily") {
                paramsData.selectedParams = ["total_precipitation", "mean_temperature", "mean_wind_speed", "mean_relative_humidity"];
                paramsData.availableParams = WeatherUtilsService.getParametersNamevalueDictList(paramsData.selectedParams);
            } else {
                paramsData.selectedParams = ["precipitation", "temperature", "wind_speed", "relative_humidity"];
                paramsData.availableParams = WeatherUtilsService.getParametersNamevalueDictList(paramsData.selectedParams);
            }
        } else {
            paramsData.availableParams = WeatherUtilsService.getParametersNamevalueDictList(paramsData.selectedParams);
        }
    }

    function defineExportWeatherDataFormConfiguration() {
        $scope.exportWeatherDataForm = [
            {
                className: "flex-33",
                type: "select",
                key: "selected_farm",
                templateOptions: {
                    required: true,
                    label: "Farm",
                    labelProp: "name",
                    valueProp: "value",
                    options: $scope.farmOptions
                }
            },


            {
                className: "flex-33",
                type: "select",
                key: "selected_field",
                templateOptions: {
                    required: false,
                    label: "Field",
                    labelProp: "name",
                    valueProp: "value",
                    options: []
                },
                controller: function ($scope, $rootScope) {
                    $scope.$watch("$parent.model.selected_farm", function handleChange(newVal, oldVal) {
                        if (newVal) {
                            FieldNamesFactory.query({farm: newVal, as_dict: "yes"}, function (result) {
                                $scope.to.options = result;
                                $rootScope.fieldOptions = result;
                            });
                        }
                    });

                }
            },
            {
                className: "flex-33",
                type: "select",
                key: "frequency",
                templateOptions: {
                    required: true,
                    label: "Frequency",
                    labelProp: "name",
                    valueProp: "value",
                    options: [
                        {'value': 'daily', 'name': 'Daily'},
                        {'value': 'hourly', 'name': 'Hourly'}
                    ]
                }
            },
            {
                className: "flex-100",
                type: "select",
                key: "queryParams",
                templateOptions: {
                    required: true,
                    multiple: true,
                    label: "Parameters",
                    labelProp: "name",
                    valueProp: "value",
                    options: paramsData.availableParams
                },
                controller: function ($scope) {
                    $scope.$watch("$parent.model.frequency", function handleChange(newVal, oldVal) {
                        if (newVal) {
                            if (newVal === 'daily') {
                                $scope.to.options = WeatherUtilsService.getParametersNamevalueDictList(exportDailyParams);
                                $scope.model.queryParams = exportDailyParams;
                            } else {
                                $scope.to.options = WeatherUtilsService.getParametersNamevalueDictList(exportHourlyParams);
                                $scope.model.queryParams = exportHourlyParams;
                            }

                        }
                    });

                }
            },
            {
                className: "flex-50",
                type: "datepicker",
                key: "startDate",
                defaultValue: moment(new Date()).subtract(6, "months").toDate(),
                templateOptions: {
                    theme: "custom",
                    label: "Start date",
                    placeholder: "Start date",
                    format: 'DD MMMM YYYY',
                    minDate: new Date(2015, 12, 1),
                    maxDate: new Date()
                }
            },
            {
                className: "flex-50",
                type: "datepicker",
                defaultValue: moment(new Date()).toDate(),
                key: "endDate",
                templateOptions: {
                    theme: "custom",
                    label: "End date",
                    placeholder: "End date",
                    format: 'DD MMMM YYYY',
                    disabled: false,
                    minDate: new Date(2015, 12, 1),
                    maxDate: new Date()
                },

                controller: function ($scope) {
                    $scope.$watch("$parent.model.startDate", function handleChange(newVal, oldVal) {
                        if (newVal) {
                            $scope.to.minDate = newVal;

                            var currentEndDate = moment($scope.model.endDate);
                            var currentStartDate = moment(newVal);
                            if (currentStartDate.isAfter(currentEndDate)) {
                                $scope.model.endDate = currentStartDate.add(1, "days").toDate();
                            }
                        }
                    });

                }
            }

        ]
        ;
    }

    function getDataToPost(exportDataQueryModel) {

        var dataToPost = {};
        dataToPost.service_name = "METEOMATICS-ACTUAL_AND_FORECAST";
        dataToPost.account_key = "kisanhub-account";

        dataToPost.start_date = WeatherUtilsService.getDbDate(exportDataQueryModel.startDate);
        dataToPost.end_date = WeatherUtilsService.getDbDate(exportDataQueryModel.endDate);
        dataToPost.parameters = String(checkWindSelectedAndAddWindDirection(exportDataQueryModel.queryParams));
        dataToPost.data_format = 'excel';
        dataToPost.localize = '1';
        dataToPost.only_observed = '1';

        if (exportDataQueryModel.frequency === 'daily') {
            dataToPost.frequency = 'daily';
            dataToPost.params_as_daily = '1';
        } else {
            dataToPost.frequency = 'hourly';
            dataToPost.params_as_daily = '0';
        }

        dataToPost.to_send = user_email;
        var farmObj = getfarmObjUsingSlug(exportDataQueryModel.selected_farm);
        var fieldObj = getfieldObjUsingSlug(exportDataQueryModel.selected_field);
        if (fieldObj) {
            dataToPost.export_object_metadata = {
                object_name: fieldObj.name + " (" + farmObj.name + ")",
                object_type: 'FarmField',
                object_slug: exportDataQueryModel.selected_field
            };
            dataToPost.location_slug = fieldObj.location_slug;
            dataToPost.gateway_slug = fieldObj.gateway_slug;
        } else {
            dataToPost.export_object_metadata = {
                object_name: farmObj.name,
                object_type: 'Farm',
                object_slug: exportDataQueryModel.selected_farm
            };
            dataToPost.location_slug = farmObj.location_slug;
        }
        return dataToPost;

    }

    $scope.exportWeatherData = function (exportDataQueryModel) {

        $mdDialog.hide();

        $resource('/api/weather/blended-export/').save(getDataToPost(exportDataQueryModel), function (apiData) {
            $khMessage.show("Request is recorded to download Weather Data. You will get Excel file link by E-Mail when process finishes", 12000);

        }, function () {
            $khMessage.show("Error while recording your download request.", 12000);
        });
    };

    function checkWindSelectedAndAddWindDirection(selectedParams) {
        var indexOfWindSpeed = selectedParams.indexOf('wind_speed');
        if (indexOfWindSpeed !== -1) {
            if (selectedParams.indexOf('wind_direction') === -1) {
                selectedParams.splice(indexOfWindSpeed + 1, 0, 'wind_direction');
            }
        } else {
            WeatherUtilsService.removeElementFromArray(selectedParams, 'wind_direction');
        }
        return selectedParams;
    }

    function getfarmObjUsingSlug(farmSlug) {
        for (var i = 0; i < $scope.farmOptions.length; i++) {
            var farmDict = $scope.farmOptions[i];
            if (farmDict.value === farmSlug) {
                return farmDict;
            }
        }
    }

    function getfieldObjUsingSlug(fieldSlug) {
        for (var i = 0; i < $rootScope.fieldOptions.length; i++) {
            var fieldDict = $rootScope.fieldOptions[i];
            if (fieldDict.value === fieldSlug) {
                return fieldDict;
            }
        }
    }

}

