angular.module("WeatherApp").controller("AddEditWeatherOverrideController",
    function ($scope,
              $rootScope,
              FieldNamesFactory,
              WeatherOverrideRecordFactory,
              $khMessage,
              editWeatherOverrideRecordData,
              $mdDialog, FarmFiltersService, WeatherUtilsService) {

        setUpModelFormData();

        function setUpModelFormData() {
            if (editWeatherOverrideRecordData) {
                $scope.weatherOverrideModelFormData = editWeatherOverrideRecordData;
                $scope.weatherOverrideModelFormData.multiple_field = false

            } else {
                $scope.weatherOverrideModelFormData = {
                    'select_days': 1,
                    'parameter': 'precipitation',
                    'selected_farm': FarmFiltersService.getSelectedFarmSlug(),
                    'selected_field': [FarmFiltersService.getSelectedFieldSlug()],
                    'multiple_field': true

                };
            }
        }

        defineWeatherOverrideDataFormConfiguration();

        $scope.saveOverride = function (weatherOverrideModelFormData) {
            var weatherOverrideDataToPost = {
                farmfield: weatherOverrideModelFormData.selected_field,
                record_date: WeatherUtilsService.getDbDate(weatherOverrideModelFormData.endDate),
                weather_param: weatherOverrideModelFormData.parameter,
                value: weatherOverrideModelFormData.parameter_value,
                number_of_days: weatherOverrideModelFormData.selected_days,
                multiple_field: weatherOverrideModelFormData.multiple_field

            };
            WeatherOverrideRecordFactory.save(weatherOverrideDataToPost, function (response_data) {

                $rootScope.$broadcast('saved_weather_override');
                $khMessage.show("Override Operation successfully done", 5000);
                $mdDialog.hide();

                if (typeof response_data.data != 'undefined') {
                    overrideSummaryDialogue(response_data);
                }


            }, function (error) {
                if (error.status) {
                    $khMessage.show("Override Operation Failed", 5000);
                }
            });
        };

        var overrideSummaryDialogue = function (response_data) {

            $mdDialog.show({
                controller: "OverrideSummaryController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/forms/weatheroverride/OverrideSummary.html',
                parent: angular.element(document.body),
                locals: {
                    response_data: response_data.data
                },
                clickOutsideToClose: false,
                escapeToClose: true
            });

        };


        function defineWeatherOverrideDataFormConfiguration() {
            $scope.editWeatherOverrideDataForm = [
                {
                    className: "flex-50",
                    type: "searchable_select",
                    key: "selected_farm",
                    defaultValue: FarmFiltersService.getSelectedFarmSlug(),
                    templateOptions: {
                        required: true,
                        disabled: true,
                        label: "Farm",
                        labelProp: "name",
                        valueProp: "value",
                        options: FarmFiltersService.getFarms()
                    }
                },

                {
                    className: "flex-50",
                    type: "searchable_select",
                    key: "selected_field",
                    defaultValue: FarmFiltersService.getSelectedFieldSlug(),
                    templateOptions: {
                        required: true,
                        disabled: true,
                        label: "Field",
                        labelProp: "name",
                        valueProp: "value",
                        options: []
                    },
                    controller: function ($scope) {
                        $scope.$watch("$parent.model.selected_farm", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                FarmFiltersService.getSelectedFieldSlug();
                                var farm = _.findWhere(FarmFiltersService.getFarms(), {'slug': newVal});
                                $scope.to.options = farm.fields;
                            }
                        });

                    }
                },
                {
                    className: "flex-40",
                    type: "select",
                    key: "parameter",
                    templateOptions: {
                        required: true,

                        label: "Parameters",
                        labelProp: "name",
                        valueProp: "value",
                        options: [{'name': "Rainfall (mm)", 'value': 'precipitation'}]
                    }
                },
                {
                    className: "flex-30",
                    type: "input",
                    key: "parameter_value",
                    templateOptions: {
                        label: "Value",
                        required: true
                    }
                },
                {
                    className: "flex-30",
                    type: "select",
                    key: "selected_days",
                    defaultValue: 1,
                    templateOptions: {
                        disabled: true,
                        label: "Days",
                        required: true,
                        options: [
                            {'name': "1", 'value': 1},
                            {'name': "2", 'value': 2},
                            {'name': "3", 'value': 3},
                            {'name': "4", 'value': 4},
                            {'name': "5", 'value': 5},
                            {'name': "6", 'value': 6},
                            {'name': "7", 'value': 7}
                        ]
                    }
                },
                {
                    className: "flex-50",
                    type: "datepicker",
                    key: "startDate",
                    templateOptions: {
                        label: "Start date",
                        disabled: true,
                        placeholder: "Start date",
                        format: 'YYYY-MM-DD',
                        required: true
                    },
                    expressionProperties: {
                        'templateOptions.maxDate': function ($viewValue, $modelValue, scope) {

                            return moment(new Date()).subtract(scope.model.selected_days - 1, "days").toDate()

                        }
                    }

                },
                {
                    className: "flex-50",
                    type: "datepicker",
                    key: "endDate",
                    templateOptions: {
                        label: "End date",
                        required: true,
                        disabled: true,
                        placeholder: "End date",
                        format: 'YYYY-MM-DD',
                        maxDate: new Date()
                    },
                    controller: function ($scope) {
                        $scope.$watch("$parent.model.startDate", function (newVal, oldValue) {

                            if (newVal) {
                                var currentStartDate = moment(newVal);
                                $scope.model.endDate = currentStartDate.add($scope.model.selected_days - 1, "days").toDate()
                            }

                        });

                        $scope.$watch("$parent.model.selected_days", function (newVal, oldValue) {

                            if (newVal) {
                                var selected_days = newVal;
                                var currentStartDate = moment($scope.model.startDate);

                                var currentEndDate = currentStartDate.add(selected_days - 1, "days").toDate();

                                if (currentEndDate > $scope.to.maxDate) {

                                    $scope.model.endDate = $scope.to.maxDate;
                                    $scope.model.startDate = moment(new Date($scope.to.maxDate)).subtract(selected_days - 1, "days").toDate()
                                }
                                else {

                                    $scope.model.endDate = currentEndDate

                                }


                            }

                        });
                    }
                }
            ];
            $scope.addWeatherOverrideDataForm = [
                {
                    className: "flex-50",
                    type: "searchable_select",
                    key: "selected_farm",
                    defaultValue: FarmFiltersService.getSelectedFarmSlug(),
                    templateOptions: {
                        required: true,
                        label: "Farm",
                        labelProp: "name",
                        valueProp: "value",
                        options: FarmFiltersService.getFarms()
                    }
                },

                {
                    className: "flex-50",
                    type: "searchable_select",
                    key: "selected_field",
                    defaultValue: FarmFiltersService.getSelectedFieldSlug(),
                    templateOptions: {
                        required: true,
                        multiple: true,
                        label: "Field",
                        labelProp: "name",
                        valueProp: "value",
                        options: []
                    },
                    controller: function ($scope) {
                        $scope.$watch("$parent.model.selected_farm", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                FarmFiltersService.getSelectedFieldSlug();
                                var farm = _.findWhere(FarmFiltersService.getFarms(), {'slug': newVal});
                                $scope.to.options = farm.fields;
                            }
                        });

                    }
                },
                {
                    className: "flex-40",
                    type: "select",
                    key: "parameter",
                    templateOptions: {
                        required: true,

                        label: "Parameters",
                        labelProp: "name",
                        valueProp: "value",
                        options: [{'name': "Rainfall (mm)", 'value': 'precipitation'}]
                    }
                },
                {
                    className: "flex-30",
                    type: "input",
                    key: "parameter_value",
                    templateOptions: {
                        label: "Value",
                        required: true
                    }
                },
                {
                    className: "flex-30",
                    type: "select",
                    key: "selected_days",
                    defaultValue: 1,
                    templateOptions: {
                        label: "Days",
                        required: true,
                        options: [
                            {'name': "1", 'value': 1},
                            {'name': "2", 'value': 2},
                            {'name': "3", 'value': 3},
                            {'name': "4", 'value': 4},
                            {'name': "5", 'value': 5},
                            {'name': "6", 'value': 6},
                            {'name': "7", 'value': 7}
                        ]
                    }
                },
                {
                    className: "flex-50",
                    type: "datepicker",
                    key: "startDate",
                    templateOptions: {
                        label: "Start date",
                        placeholder: "Start date",
                        format: 'YYYY-MM-DD',
                        required: true
                    },
                    expressionProperties: {
                        'templateOptions.maxDate': function ($viewValue, $modelValue, scope) {

                            return moment(new Date()).subtract(scope.model.selected_days - 1, "days").toDate()

                        }
                    }

                },
                {
                    className: "flex-50",
                    type: "datepicker",
                    key: "endDate",
                    templateOptions: {
                        label: "End date",
                        required: true,
                        disabled: true,
                        placeholder: "End date",
                        format: 'YYYY-MM-DD',
                        maxDate: new Date()
                    },
                    controller: function ($scope) {
                        $scope.$watch("$parent.model.startDate", function (newVal, oldValue) {

                            if (newVal) {
                                var currentStartDate = moment(newVal);
                                $scope.model.endDate = currentStartDate.add($scope.model.selected_days - 1, "days").toDate()
                            }

                        });

                        $scope.$watch("$parent.model.selected_days", function (newVal, oldValue) {

                            if (newVal) {
                                var selected_days = newVal;
                                var currentStartDate = moment($scope.model.startDate);

                                var currentEndDate = currentStartDate.add(selected_days - 1, "days").toDate();

                                if (currentEndDate > $scope.to.maxDate) {

                                    $scope.model.endDate = $scope.to.maxDate;
                                    $scope.model.startDate = moment(new Date($scope.to.maxDate)).subtract(selected_days - 1, "days").toDate()
                                }
                                else {

                                    $scope.model.endDate = currentEndDate

                                }


                            }

                        });
                    }
                }
            ];
        }
    });

angular.module("WeatherApp").controller("OverrideSummaryController",
    function ($scope,
              $khMessage,
              response_data,
              NgTableParams) {

        $scope.responseTable = new NgTableParams({}, {dataset: response_data});
        $scope.isEmptyTableDataMessage = response_data.length === 0;
    });

