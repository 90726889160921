(function (angular) {
    'use strict';
    angular.module('WeatherApp')
        .controller('PublicDataSourceDetailController', PublicDataSourceDetailController);

    PublicDataSourceDetailController.$inject = ['$scope', '$rootScope', 'WeatherUtilsService'];

    function PublicDataSourceDetailController($scope, $rootScope, WeatherUtilsService) {
        var vm = $scope;
        vm.pds_detail = $rootScope.pds;
        vm.latestEAData = $rootScope.pds.latestReading;
        vm.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
        vm.parameterUnitMap = WeatherUtilsService.parameterUnitMap();
    }

})(angular);
