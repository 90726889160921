angular.module("WeatherApp").controller('AddEditPWSAssignedFieldsController',
    function ($scope, $rootScope, FarmNamesFactory, FieldNamesFactory,
              PWSFactory, PWSAssignFieldsDataFactory, $khMessage, $mdDialog, FarmFiltersService) {

        $scope.stationOptions = getStationOptions($rootScope.PWSApiJsonData);

        FarmNamesFactory.query(function (apiData) {
            $scope.farmOptions = apiData;
        });

        $scope.onFarmSelect = function (farm) {
            FieldNamesFactory.query({farm: farm.value, as_dict: "yes"}, function (result) {
                $rootScope.fieldOptions = result;
                $scope.AvailableListItems = [];
                $scope.AvailableListItems.push(result);
                $scope.onStationSelect($rootScope.selected_station_detail);
            });
        };

        $scope.onStationSelect = function (selected_station) {
            PWSAssignFieldsDataFactory.get({gateway_slug: selected_station.value}, function (apiData) {
                $scope.associated_fields = apiData.data;
                $scope.SelectedListItems = [];
                $scope.SelectedListItems.push($scope.associated_fields);
            });
        };

        $scope.applyAssignedFields = function (selected_station) {
            var gateway_slug = selected_station.value;
            var fields = [];
            for (var field in $scope.SelectedListItems[0]) {

                fields.push($scope.SelectedListItems[0][field].value);
            }
            var post_dict = {
                fields: fields,
                gateway_slug: gateway_slug
            };

            PWSAssignFieldsDataFactory.save(post_dict, function (response) {
                $khMessage.show("Fields Assigned successfully.", 5000);
                $mdDialog.hide();
                //Creating Object as performing membership operation is faster with Object as compared to array
                var i, fields_dict = {};
                for (i in fields) {
                    fields_dict[fields[i]] = true; //Some random value
                }
                for (i in $rootScope.fields_list) {
                    var field_obj = $rootScope.fields_list[i];
                    if (field_obj.value && field_obj.value in fields_dict) {
                        field_obj.gateway_slug = gateway_slug
                    }
                    else if(field_obj.gateway_slug && field_obj.gateway_slug === gateway_slug){
                        field_obj.gateway_slug = null;
                    }
                }
                return response;
            });

        };


        $scope.selected_station = $rootScope.selected_station_detail;
        $scope.selected_farm = FarmFiltersService.getFarms()[0];
        $scope.onStationSelect($scope.selected_station);
        $scope.onFarmSelect($scope.selected_farm);
        $scope.selectFaIndex = 0;
        $scope.SelectedAvailItems = [];
        $scope.SelectedSelectedListItems = [];
        $scope.selectFaIndex = $scope.FaName = "0";
        $scope.OnAvailableChange = function () {
            $scope.AvailLength = $scope.SelectedAvailItems.length;
        };

        $scope.cancelEditAssignedFields = function () {
            $mdDialog.cancel();
        };

        var comparer = function (otherArray) {
            return function (current) {
                return otherArray.filter(function (other) {
                    return other.value === current.value
                }).length === 0;
            }
        };

        $scope.moveSelectedRight = function () {
            if ($scope.SelectedAvailItems.filter(comparer($scope.SelectedListItems[0])).length > 0) {
                angular.forEach($scope.SelectedAvailItems, function (value, key) {
                    this.push(value);
                }, $scope.SelectedListItems[$scope.selectFaIndex]);

                angular.forEach($scope.SelectedAvailItems, function (value, key) {
                    for (var index = $scope.AvailableListItems[$scope.selectFaIndex].length - 1; index >= 0; index--) {
                        if ($scope.AvailableListItems[$scope.selectFaIndex][index].name === value.name) {
                            $scope.AvailableListItems[$scope.selectFaIndex].splice(index, 1);
                        }
                    }
                });
                $scope.SelectedAvailItems = [];
            } else {
                $khMessage.show('Field/Fields already assigned to Weather Station')
            }
        };

        $scope.moveAllRight = function () {
            angular.forEach($scope.AvailableListItems[$scope.selectFaIndex], function (value, key) {
                this.push(value);
            }, $scope.SelectedListItems[$scope.selectFaIndex]);

            for (var index = $scope.AvailableListItems[$scope.selectFaIndex].length - 1; index >= 0; index--) {
                $scope.AvailableListItems[$scope.selectFaIndex].splice(index, 1);
            }

        };

        $scope.moveSelectedLeft = function () {
            angular.forEach($scope.SelectedSelectedListItems, function (value, key) {
                this.push(value);
            }, $scope.AvailableListItems[$scope.selectFaIndex]);

            angular.forEach($scope.SelectedSelectedListItems, function (value, key) {
                for (var index = $scope.SelectedListItems[$scope.selectFaIndex].length - 1; index >= 0; index--) {
                    if ($scope.SelectedListItems[$scope.selectFaIndex][index].name === value.name) {
                        $scope.SelectedListItems[$scope.selectFaIndex].splice(index, 1);
                    }
                }
            });
            $scope.SelectedSelectedListItems = [];
        };

        $scope.moveAllLeft = function () {
            angular.forEach($scope.SelectedListItems[$scope.selectFaIndex], function (value, key) {
                this.push(value);
            }, $scope.AvailableListItems[$scope.selectFaIndex]);

            for (var index = $scope.SelectedListItems[$scope.selectFaIndex].length - 1; index >= 0; index--) {
                $scope.SelectedListItems[$scope.selectFaIndex].splice(index, 1);
            }
            $scope.SelectedSelectedListItems = [];
        };

        function getStationOptions(pwsListAPIData) {
            var station_options = [];
            for (var index = 0; index < pwsListAPIData.length; index++) {

                station_options.push(
                    {
                        'name': pwsListAPIData[index].properties.provider_reference,
                        'value': pwsListAPIData[index].properties.slug
                    }
                )
            }
            return station_options
        }


    });
