(function (angular) {
    'use strict';
    angular.module('user_notification_preferences')
        .controller('UserNotificationSubscriptionController', ['$scope', '$rootScope', '$mdDialog', 'configDict',
            'UserNotificationSubscriptionHelperFactory', 'UserNotificationSubscriptionConstantsFactory',
            'UserNotificationSubscriptionFactory',
            '$khMessage',
            'ProfileFactory',
            'UserAccountSettingFactory',
            'countries',
            UserNotificationSubscriptionController]);


    function UserNotificationSubscriptionController($scope, $rootScope, $mdDialog, configDict, UserNotificationSubscriptionHelperFactory,
                                                    UserNotificationSubscriptionConstantsFactory,
                                                    UserNotificationSubscriptionFactory,
                                                    $khMessage,
                                                    ProfileFactory,
                                                    UserAccountSettingFactory,
                                                    countries) {


        start();

        function start() {
            getUserNotificationConstants();
        }


        var profile;
        ProfileFactory.get(function (result) {
            delete result['picture'];
            $scope.profile_data = result;
            if ($scope.profile_data.mobile) {
                $scope.profile_data.country_code = $scope.profile_data.mobile.slice(0, $scope.profile_data.mobile.length - 10);
                $scope.profile_data.mobile = $scope.profile_data.mobile.replace($scope.profile_data.country_code, '');
            }
            profile = angular.copy($scope.profile_data);

        });
        $scope.countries = countries;

        $scope.$watch('user_details.country', function (newval, oldval) {
            if (newval || oldval) {
                _.find($scope.countries, function (val) {
                    if (val.value == $scope.user_details.country) {
                        $scope.user_details['country_code'] = val.dial_code;
                    }
                });
            }
        });


        UserAccountSettingFactory.get({id: settings_id}, function (result) {
            $scope.user = result;
        });

        /**
         * =======================================================================================
         * Set UP functions
         * =======================================================================================
         */

        function setUpDialogBox() {
            /**
             * =======================================================================================
             * Sets form config based on for both new notification addition and existing notifications,
             * Following are the parameter used while setting up different type form
             * 1. Addition type (can be bulk addition or single object type addition)
             * 2. Object type (can be field, pws)
             * =======================================================================================
             */
            configDict['userNotificationConstants'] = $scope.userNotificationConstants;
            $scope.diologtitle = configDict['name'];

            setUpFormForExistingData(configDict);
            setUpFormForNewData(configDict);
            setUpUserDetailsForm();
        }


        function setUpFormForExistingData(configDict) {
            configDict['formFor'] = 'existingData';
            $scope.existingUserNotificationForm = UserNotificationSubscriptionHelperFactory.getModelFormConfig(configDict);
            $scope.inactiveUserNotificationForm = UserNotificationSubscriptionHelperFactory.getModelFormConfig(configDict);
        }

        function setUpFormForNewData(configDict) {
            var allAvailableNotifications = getRemainingNotificationTypesToBeAdded();
            if (allAvailableNotifications.length != 0) {
                configDict['formFor'] = 'newData';
                configDict['availableNotificationTypes'] = allAvailableNotifications;
                $scope.selected_new_notification = allAvailableNotifications[0].value;
                $scope.newUserNotificationForm = UserNotificationSubscriptionHelperFactory.getModelFormConfig(configDict, $scope);
                $scope.newNotifications = [{}];
            } else {
                $scope.newNotifications = [];
            }
        }

        function setUpUserDetailsForm() {

            $scope.user_details_form = [

                {
                    className: "flex-30",
                    type: "input",
                    key: "email",
                    id: "email",
                    templateOptions: {
                        label: "Email",
                        type: 'email',
                        required: true,
                        placeholder: "E-mail"
                    }
                },
                {
                    "className": "flex-25",
                    "key": "country",
                    "type": "searchable_select",
                    "templateOptions": {
                        "label": "Country",
                        "labelProp": "fullname",
                        "required": true,
                        "valueProp": "value",
                        "options": $scope.countries
                    }
                },
                {
                    "className": "flex-25",
                    "key": "country_code",
                    "type": "input",
                    "templateOptions": {
                        "label": "Dial Code",
                        disabled: true
                    }
                },
                {
                    className: "flex-20",
                    type: "input",
                    key: "phone_no",
                    id: "phone_no",
                    "templateOptions": {
                        "label": "Mobile",
                        maxlength: 10,
                        minlength: 10,
                        required: true
                    }
                }
            ];

            $scope.user_details = {
                'email': $scope.user.email,
                'country': $scope.profile_data['country'],
                'country_code': $scope.profile_data['country_code'],
                'phone_no': $scope.profile_data.mobile
            }

        }

        function getRemainingNotificationTypesToBeAdded() {
            var allAvailableNotifications = angular.copy($scope.userNotificationConstants.notification_types[configDict['object_key']]);
            angular.forEach($scope.completeExistingNotifications, function (notification, completeExistingNotificationsIndex) {
                    angular.forEach(allAvailableNotifications, function (availableNotification, availableNotificationIndex) {
                        if (availableNotification.value == notification.notification_types[0]) {
                            allAvailableNotifications.splice(availableNotificationIndex, 1);
                        }
                    });
                }
            );
            return allAvailableNotifications;
        }

        function getExistingNotifications() {
            UserNotificationSubscriptionFactory.get({
                object_slug: configDict['object_slug'],
                object_key: configDict['object_key']
            }, function (apiData) {

                $scope.completeExistingNotifications = apiData.data;
                $scope.inactiveNotifications = [];
                $scope.existingNotifications = [];

                angular.forEach(apiData.data, function (notification, key) {

                    if (notification.is_valid) {


                        if (testIfNotificationIsInactive(notification.notification_types)) {
                            $scope.existingNotifications.push(notification);
                        }
                    } else {


                        if (testIfNotificationIsInactive(notification.notification_types)) {
                            $scope.inactiveNotifications.push(notification);
                        }

                    }
                });
                setUpDialogBox();
            });

        }

        function getUserNotificationConstants() {
            UserNotificationSubscriptionConstantsFactory.get(function (apiData) {
                $scope.userNotificationConstants = apiData.data;
                $scope.no_notification_configured = false;

                if ($scope.userNotificationConstants['notification_types'][configDict['object_key']].length == 0) {

                    $scope.no_notification_configured = true;

                }

                getExistingNotifications();
            });

        }

        function testIfNotificationIsInactive(notification_type) {
            var result = false;

            angular.forEach($scope.userNotificationConstants['notification_types'][configDict['object_key']], function (value, key) {

                if (notification_type == value.value) {

                    result = true;
                    return result;
                }
            });

            return result;
        }

        /**
         * =======================================================================================
         * User activity functions
         * =======================================================================================
         */

        $scope.saveProfile = function () {
            delete $scope.profile_data['subscribed_organization'];
            delete $scope.profile_data['permission'];
            delete $scope.profile_data['secondary_team'];
            delete $scope.profile_data['list_of_applications'];
            delete $scope.profile_data['subscription_package'];
            angular.forEach($scope.profile_data, function (value, key) {
                if (!value && profile[key] == $scope.profile_data[key]) {
                    delete $scope.profile_data[key];
                }
            });
            if ($scope.profile_data['mobile'] && $scope.profile_data['country_code']) {
                $scope.profile_data['mobile'] = $scope.profile_data['country_code'] + $scope.profile_data['mobile'];
            }
            $scope.profile_data.$update({user_slug: $scope.profile_data.user_slug}, function (result) {
                $scope.profile_data = result;
                $rootScope.$broadcast('profile_edited');
                $mdDialog.hide();
            }, function (error) {
                $khMessage.show('Failed to update mobile number');
            })
        };

        $scope.updateAccount = function (data) {
            if (data['password']) {
                var redirect = true;
            }
            delete data['password2'];
            $scope.user.$update(function (result) {
                    if (redirect) {
                        location.reload();
                    }
                },
                function (error) {
                    $khMessage.show('Failed to update Email address');
                }
            );
        };

        $scope.saveNotifications = function () {
            $scope.user.email = $scope.user_details.email;
            $scope.profile_data.mobile = $scope.user_details.phone_no;
            $scope.profile_data.country = $scope.user_details.country;
            $scope.profile_data.country_code = $scope.user_details.country_code;
            $scope.updateAccount($scope.user);
            $scope.saveProfile();
            pushSelectedObjectDetails();
            var userNotifications = angular.copy($scope.existingNotifications);
            userNotifications.push.apply(userNotifications, $scope.inactiveNotifications);
            userNotifications.push.apply(userNotifications, $scope.newNotifications);

            UserNotificationSubscriptionFactory.save({'object_key': configDict['object_key']}, userNotifications, function (apiData) {
                $khMessage.show('Notifications saved successfully.', 5000);
                $mdDialog.cancel();
            }, function (error) {
                if (error.status) {
                    $khMessage.show('Failed to save notifications. Please contact support team', 5000)
                }
            });

        };

        function pushSelectedObjectDetails() {
            angular.forEach($scope.newNotifications, function (notification, index) {
                    if (Object.keys(notification).length != 0) {
                        notification['subscribed_objects'] = [configDict['object_slug']];
                    } else {
                        $scope.newNotifications.splice(index, 1);
                    }
                });

        }

        $scope.cancelNotificationChanges = function () {
            $mdDialog.cancel();
        };

    }

})(angular);