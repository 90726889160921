angular.module("WeatherApp").controller('PWSDataExportController',
    function ($scope, $rootScope, $resource,dataFromParentController, $mdDialog, $khMessage, WeatherUtilsService) {

        loadStationDataStartDate($rootScope.PWSApiJsonData);
        defineExportWeatherDataFormConfiguration();

        populatedDefaultData();

        function populatedDefaultData() {
            $scope.exportDataQueryModel = {
                data_frequency: dataFromParentController["data_frequency"],
                selectedGateway: dataFromParentController["slug"]
            };
            var gateway = getSelectedGatewayObj(dataFromParentController["slug"]);
            $scope.exportDataQueryModel['startDate'] = gateway.properties.nodes[0].slug;

            if (dataFromParentController["start_date"]) {
                $scope.exportDataQueryModel['startDate'] = dataFromParentController["start_date"]
            } else if (gateway.properties.data_start_date) {
                if (moment(gateway.properties.data_start_date).isAfter(moment(new Date()).subtract(30, "days"))) {
                    $scope.exportDataQueryModel['startDate'] = moment(gateway.properties.data_start_date, 'YYYY-MM-DD').toDate()
                } else {
                    $scope.exportDataQueryModel['startDate'] = moment(new Date()).subtract(30, "days").toDate()
                }
            }
            if (dataFromParentController["end_date"]) {
                $scope.exportDataQueryModel['endDate'] = dataFromParentController["end_date"]
            }
            if (dataFromParentController["selected_parameters"]) {
                $scope.exportDataQueryModel['queryParams'] = dataFromParentController["selected_parameters"]
            }
        }

        function buildAvailableNodeDropDown(gateway) {
            var nodeDropDown = [];
            angular.forEach(gateway.properties.nodes, function (node, index) {
                nodeDropDown.push({
                    'name': node.node_id,
                    'value': node.slug
                })
            });
            return nodeDropDown
        }

        function defineExportWeatherDataFormConfiguration() {
            $scope.exportWeatherDataForm = [
                {
                    className: "flex-100",
                    type: "select",
                    key: "selectedGateway",
                    templateOptions: {
                        required: true,
                        label: "Station",
                        labelProp: "name",
                        valueProp: "value",
                        options: getGatewayOptions(angular.copy($rootScope.PWSApiJsonData))
                    }
                },

                {
                    className: "flex-100",
                    type: "select",
                    key: "selectedNode",
                    templateOptions: {
                        required: true,
                        label: "Sensor group",
                        labelProp: "name",
                        valueProp: "value",
                        options: []
                    },
                    hideExpression: function ($viewValue, $modelValue, scope) {
                        var selectedGatewayObj = getSelectedGatewayObj(scope.model.selectedGateway);
                        return selectedGatewayObj.properties.nodes.length === 1
                    },
                    controller: function ($scope) {
                        /**
                         * Each Gateway has different set of weather parameters.
                         * This controller is kept so that when Gateway changes, it updates available parameters options
                         * and removes selected parameters.
                         */
                        $scope.$watch("$parent.model.selectedGateway", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                var selectedGatewayObj = getSelectedGatewayObj($scope.model.selectedGateway);
                                $scope.to.options = buildAvailableNodeDropDown(selectedGatewayObj);
                                $scope.model.selectedNode = selectedGatewayObj.properties.nodes[0].slug;
                            }
                        });
                    }
                },

                {
                    className: "flex-100",
                    type: "select",
                    defaultValue: "daily",
                    key: "data_frequency",
                    templateOptions: {
                        required: true,
                        label: "Data frequency",
                        labelProp: "name",
                        valueProp: "value",
                        options: [
                            {"name": "Daily", "value": "daily"},
                            {"name": "Intraday", "value": "instantaneous"}
                        ]

                    }
                },
                {
                    className: "flex-100",
                    type: "select",
                    key: "queryParams",
                    templateOptions: {
                        required: true,
                        multiple: true,
                        label: "Parameters",
                        labelProp: "name",
                        valueProp: "value",
                        options: []
                    },
                    controller: function ($scope) {
                        /**
                         * Each Gateway has different set of weather parameters.
                         * This controller is kept so that when Gateway changes, it updates available parameters options
                         * and removes selected parameters.
                         */
                        $scope.$watch("$parent.model.selectedGateway", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                var availableParameters = getNodeSelectableParams(newVal, $scope.model.selectedNode, $scope.model.data_frequency);
                                $scope.to.options = getParamDropDown(availableParameters);
                                $scope.model.queryParams = angular.copy(availableParameters).slice(0, 3);
                            }
                        });

                        /**
                         * Each Gateway has different set of weather parameters.
                         * This controller is kept so that when Gateway changes, it updates available parameters options
                         * and removes selected parameters.
                         */

                        $scope.$watch("$parent.model.selectedNode", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                var availableParameters = getNodeSelectableParams($scope.model.selectedGateway, newVal, $scope.model.data_frequency);
                                $scope.to.options = getParamDropDown(availableParameters);
                                $scope.model.queryParams = angular.copy(availableParameters).slice(0, 3);
                            }
                        });

                        /**
                         * Since there are daily parameters (like ref ET, max, min of all params) and hourly params so based on selected frequency
                         * available param drop will change
                         */
                        $scope.$watch("$parent.model.data_frequency", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                var availableParameters = getNodeSelectableParams($scope.model.selectedGateway, $scope.model.selectedNode, newVal);
                                $scope.to.options = getParamDropDown(availableParameters);
                                $scope.model.queryParams = angular.copy(availableParameters).slice(0, 3);
                                var selectionMatch = dataSelectionMatchWithParentControllerChoices($scope.model.selectedGateway, $scope.model.selectedNode, newVal);
                                if (selectionMatch) {
                                    $scope.model.queryParams = dataFromParentController["selected_parameters"]
                                }
                            }
                        });

                    }
                },
                {
                    className: "flex-100",
                    type: "datepicker",
                    key: "startDate",
                    templateOptions: {
                        label: "Start date",
                        theme: "custom",
                        placeholder: "Start date",
                        format: 'DD MMMM YYYY',
                        maxDate: new Date()
                    },
                    controller: function ($scope) {
                        /**
                         * Each Gateway has different data start date.
                         * The watch on Gateway is kept so that when Gateway changes, it updates data start date
                         * (min available date to fetch)
                         */
                        $scope.$watch("$parent.model.selectedGateway", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                $scope.to.minDate = $rootScope.gateway_data_start_date_dict[newVal];
                            }
                        });
                    }
                },
                {
                    className: "flex-100",
                    type: "datepicker",
                    defaultValue: moment(new Date()).toDate(),
                    key: "endDate",
                    templateOptions: {
                        label: "End date",
                        theme: "custom",
                        placeholder: "End date",
                        format: 'DD MMMM YYYY',
                        disabled: false,
                        maxDate: new Date()
                    },
                    controller: function ($scope) {
                        /**
                         * Each Gateway has different data start date.
                         * The watch on Gateway is kept so that when Gateway changes, it updates data start date
                         * (min available date to fetch)
                         */
                        $scope.$watch("$parent.model.selectedGateway", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                $scope.to.minDate = $rootScope.gateway_data_start_date_dict[newVal];
                            }
                        });

                        $scope.$watch("$parent.model.startDate", function handleChange(newVal, oldVal) {
                            if (newVal) {
                                $scope.to.minDate = newVal;

                                var currentEndDate = moment($scope.model.endDate);
                                var currentStartDate = moment(newVal);
                                if (currentStartDate.isAfter(currentEndDate)) {
                                    $scope.model.endDate = currentStartDate.add(1, "days").toDate();
                                }
                            }
                        })
                    }
                }

            ]
            ;
        }

        function dataSelectionMatchWithParentControllerChoices(selectedGateway, selectedNode, selectedDataFrequency) {
            try {
                return selectedGateway === dataFromParentController['slug'] && selectedNode === dataFromParentController['node_slug'] && selectedDataFrequency === dataFromParentController['data_frequency']
            }
            catch (error) {
                return false
            }
        }

        function getGatewayOptions(pwsListAPIData) {
            var gateway_options = [];
            for (var i = 0; i < pwsListAPIData.length; i++) {
                gateway_options.push(
                    {
                        'name': pwsListAPIData[i].properties.provider_reference,
                        'value': pwsListAPIData[i].properties.slug
                    }
                )
            }
            return gateway_options
        }

        function getParamDropDown(availableParameters) {
            var parameterDropDown = [];
            angular.forEach(availableParameters, function (parameter, index) {
                    {
                        parameterDropDown.push({
                            'name': WeatherUtilsService.parameterUINameConvertorMap()[parameter],
                            'value': parameter
                        })
                    }
                }
            );
            return parameterDropDown
        }

        function getNodeSelectableParams(gateway_slug, node_slug, data_frequency) {
            var selectedGatewayObj = getSelectedGatewayObj(gateway_slug);
            var nodeParamMap = buildNodeParamMap(selectedGatewayObj);
            var availableParameters;
            if (data_frequency === "instantaneous") {
                availableParameters = angular.copy(nodeParamMap[node_slug].parameters.instantaneous);
            } else {
                availableParameters = angular.copy(nodeParamMap[node_slug].parameters.daily);
            }
            return availableParameters
        }

        function loadStationDataStartDate(pwsListAPIData) {
            var gateway_data_start_date_dict = {};
            for (var i = 0; i < pwsListAPIData.length; i++) {
                var dataStartDateString = pwsListAPIData[i].data_start_date;
                gateway_data_start_date_dict[pwsListAPIData[i].slug] = moment(dataStartDateString, 'YYYY-MM-DD').toDate();
            }
            $rootScope.gateway_data_start_date_dict = gateway_data_start_date_dict;
        }


        function getDataToPost(exportDataQueryModel) {
            var dataToPost = {};
            dataToPost.start_date = WeatherUtilsService.getDbDate(exportDataQueryModel.startDate);
            dataToPost.end_date = WeatherUtilsService.getDbDate(exportDataQueryModel.endDate);
            dataToPost.parameters = String(exportDataQueryModel.queryParams);
            dataToPost.data_format = 'excel';
            dataToPost.localize = '1';

            if (exportDataQueryModel.data_frequency === 'daily') {
                dataToPost.frequency = 'daily';
                dataToPost.params_as_daily = '1';
            } else {
                dataToPost.frequency = 'instantaneous';
                dataToPost.params_as_daily = '0';
            }

            dataToPost.gateway_slug = exportDataQueryModel.selectedGateway;
            dataToPost.node_slug = exportDataQueryModel.selectedNode;

            dataToPost.to_send = user_email;
            var selectedGatewayObj = getSelectedGatewayObj(exportDataQueryModel.selectedGateway);
            var nodeName = getNodeName(selectedGatewayObj, exportDataQueryModel.selectedNode);
            var stationExportName;
            if (nodeName) {
                stationExportName = nodeName + " (" + selectedGatewayObj.properties.provider_reference + ")"
            } else {
                stationExportName = selectedGatewayObj.properties.provider_reference
            }
            dataToPost.export_object_metadata = {
                object_name: stationExportName,
                object_type: 'Gateway',
                object_slug: exportDataQueryModel.selectedGateway
            };
            return dataToPost;
        }

        function getNodeName(selectedGatewayObj, node_slug) {
            if (selectedGatewayObj.properties.nodes.length === 1) {
                return null
            }
            else {
                var nodeDetailMap = buildNodeParamMap(selectedGatewayObj);
                return nodeDetailMap[node_slug].node_id
            }
        }

        function getSelectedGatewayObj(slug) {
            var filtered_gateway_list = _.filter(angular.copy($rootScope.PWSApiJsonData), function (gateway) {
                    return gateway.properties.slug == slug;
                }
            );
            return filtered_gateway_list[0];
        }


        $scope.exportWeatherData = function (exportDataQueryModel) {
            $resource('/api/weather/sensor-data/').save(getDataToPost(exportDataQueryModel), function (apiData) {
                $khMessage.show("Request is recorded to download Weather Data. You will get Excel file link by E-Mail when process finishes", 12000);

            }, function () {
                $khMessage.show("Error while recording your download request.", 12000);
            });
            $mdDialog.hide();
        };

        /**
         * This watch is kept to make node change when gate way selection is changes and node section of formly field is in hide mode
         * */
        $scope.$watch("exportDataQueryModel.selectedGateway", function handleChange(newVal, oldVal) {
            if (newVal) {
                var selectedGatewayObj = getSelectedGatewayObj($scope.exportDataQueryModel.selectedGateway);
                if (selectedGatewayObj.properties.nodes.length === 1) {
                    $scope.exportDataQueryModel.selectedNode = selectedGatewayObj.properties.nodes[0].slug;
                }

            }
        });

        function buildNodeParamMap(gateway) {
            var nodeParamMap = {};
            angular.forEach(gateway.properties.nodes, function (node, index) {
                nodeParamMap[node.slug] = node
            });

            return nodeParamMap
        }


    });
