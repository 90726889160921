///////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////   Base Modules   ////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

import '../../../../static/assets/khapps2/base/sidenav/sidenav_module.js';
import '../../../../static/assets/khapps2/base/GenericFiltersModule.js';
import '../../../../static/assets/khapps/message_notification/message_notification_app.js';
import '../../../../static/assets/khapps2/base/navbar/navbar_module.js';
import '../../../../static/assets/khapps/settings_v2/general/profile/profile_app.js';
import '../../../../static/assets/khapps/settings_v2/general/user_settings/user_settings_app.js';
import '../../../../static/assets/khapps/settings_v2/teams/platform_statistics/platform_statistics_app.js';
import '../../../../static/assets/khapps/settings_v2/general/account_settings/account_settings_app.js';
import '../../../../static/assets/khapps/settings_v2/teams/teams_app.js';
import '../../../../static/assets/khapps/settings_v2/settings_app.js';

import '../../../../static/assets/khapps2/base/khutils/khcollapse_module.js';
import '../../../../static/assets/khapps2/base/khutils/khtabindex_module.js';
import '../../../../static/assets/khapps2/base/khutils/khaccordian_module.js';
import '../../../../static/assets/khapps2/base/khutils/khutils_module.js';
import '../../../../static/assets/khapps2/base/mdIcons/mdIcons_module.js';
import '../../../../static/assets/khapps2/base/account/KisanHubAccountAccess.js';
import '../../../../static/assets/khapps/api_utils/settings/django_site/apiUtils_settings_djangoSite_module.js';
import '../../../../static/assets/khapps2/base/secondary_navbar/secondary_navbar_module.js';
import '../../../../static/assets/khapps2/base/base_module.js';
import '../../../../static/assets/khapps2/base/khformelements/khformelements_module.js';
import '../../../../static/assets/khapps2/base/khutils/khFileUploads/khFileUploads.js';
// ===================== API Utils Modules ==================================================
import '../../../../static/assets/khapps/api_utils/khauth/auth/apiUtils_khauth_auth_module.js';
import '../../../../static/assets/khapps/api_utils/khauth/apiUtils_khauth_module.js';
import '../../../../static/assets/khapps/api_utils/khutils/teams/apiUtils_khutils_teams_module.js';
import '../../../../static/assets/khapps/api_utils/khutils/seasons/apiUtils_khutils_seasons_module.js';
import '../../../../static/assets/khapps/api_utils/khutils/ui/apiUtils_khutils_ui_module.js';
import '../../../../static/assets/khapps/api_utils/khutils/apiUtils_khutils_module.js';

import '../../../../static/assets/khapps/api_utils/fms/fieldplotcrop/apiUtils_fms_fieldplotcrop_module.js';
import '../../../../static/assets/khapps/api_utils/fms/crop_progress/apiUtils_fms_cropProgress_module.js';
import '../../../../static/assets/khapps/api_utils/fms/farmfield/apiUtils_fms_farmfield_module.js';
import '../../../../static/assets/khapps/api_utils/fms/apiUtils_fms_module.js';

import '../../../../static/assets/khapps/api_utils/growers_dashboard/dashboard/apiUtils_crop_dashboard_module.js';
import '../../../../static/assets/khapps/api_utils/growers_dashboard/apiUtils_growers_dashboard_module.js';
import '../../../../static/assets/khapps/api_utils/ksat/apiUtils_ksat_module.js';
import '../../../../static/assets/khapps/api_utils/notification/user_preferences/apiUtils_notification_userPreferences_module.js';

import '../../../../static/assets/khapps/api_utils/lab/external/pepsico/storage_net/apiUtils_lab_external_pepsiCo_storageNet_module.js';
import '../../../../static/assets/khapps/api_utils/lab/external/pepsico/apiUtils_lab_external_pepsiCo_module.js';
import '../../../../static/assets/khapps/api_utils/lab/external/apiUtils_lab_external_module.js';
import '../../../../static/assets/khapps/api_utils/lab/sample/apiUtils_lab_sample_module.js';
import '../../../../static/assets/khapps/api_utils/lab/apiUtils_lab_module.js';

import '../../../../static/assets/khapps/api_utils/dms/apiUtils_dms_module.js';

import '../../../../static/assets/khapps/api_utils/utilities/apiUtils_utilities_module.js';

import '../../../../static/assets/khapps/api_utils/crowdsource/apiUtils_crowdsource_module.js';
import '../../../../static/assets/khapps/api_utils/crops/potato/admin/apiUtils_crop_potato_admin_module.js';
import '../../../../static/assets/khapps/api_utils/crops/potato/burndown_modeller/apiUtils_crop_potato_burndown_module.js';
import '../../../../static/assets/khapps/api_utils/crops/potato/dashboard/apiUtils_crop_potato_dashboard_module.js';
import '../../../../static/assets/khapps/api_utils/crops/potato/yield_dig/apiUtils_crop_potato_yieldDig_module.js';
// ===================== Others ==================================================
import '../../../../static/assets/khapps2/base/secondary_filter_bar/secondary_filter_bar_app.js';
import '../../../../static/assets/khapps/external/external_pages/external_pages_app.js';
import '../../../../static/assets/khapps/profiles/share_with/share_with_app.js';
import '../../../../static/assets/khapps/khauth/khauthapp.js';
import '../../../../static/assets/khapps/user_notification_preferences/user_notification_preferences_app.js';
import '../../../../static/assets/khapps/khchart/chart_app.js';
import '../../../../static/assets/khapps/ksat/ksatapp.js';
import '../../../../static/assets/khapps/sat_book/sat_book_app.js';
import '../../../../static/assets/khapps/crop_book/cropbookapp.js';
import '../../../../static/assets/khapps/geodataexport/geodataexportapp.js';
import '../../../../static/assets/khapps/geodataprint/geodataprintapp.js';
import '../../../../static/assets/khapps/article_adapter_module/article_adapter_module.js';
import '../../../../static/assets/khapps/articles/articles_factories/articles_factories.js';
import '../../../../static/assets/khapps/articles/summary/articles_summary.js';
import '../../../../static/assets/khapps/articles/articles_add_edit_module/articles_add_edit_module.js';
import '../../../../static/assets/khapps/content_delivery_system/content_delivery_system.js';
import '../../../../static/assets/khapps/articles/suggested_links/suggested_links_module.js';
import '../../../../static/assets/khapps/articles/setup_module/articles_setup_module.js';
import '../../../../static/assets/khapps/articles/articles_app.js';
import '../../../../static/assets/khapps/events/events_app.js';
import '../../../../static/assets/khapps/dashboard/dashboard_app.js';
import '../../../../static/assets/khapps/fms_app/fms_app.js';
import '../../../../static/assets/khapps/weather/weather_app.js';

import '../../../../static/assets/khapps/trial/trial_app.js';
import '../../../../static/assets/khapps/my_reports/factories/my_reports_factories_module.js';
import '../../../../static/assets/khapps/my_reports/my_reports_listing/my_reports_listing_module.js';
import '../../../../static/assets/khapps/my_reports/my_reports_widget/my_reports_widget_module.js';
import '../../../../static/assets/khapps/my_reports/my_reports_app.js';
import '../../../../static/assets/khapps/lab/lab_app.js';
import '../../../../static/assets/khapps/dss/dss_irrigation/dss_irrigation_app.js';
import '../../../../static/assets/khapps/dss/dss_pestdisease/dss_pestdisease_app.js';
import '../../../../static/assets/khapps/dss/setup/dss_setup_app.js';
import '../../../../static/assets/khapps/dss/dss_main/dss_main_app.js';
import '../../../../static/assets/khapps/crowdsource/WheatYellowRustDisease/wheat_yellow_rust_disease_app.js';
import '../../../../static/assets/khapps/crowdsource/OilSeedRapeGrowth/oil_seed_rape_growth_app.js';
import '../../../../static/assets/khapps/crowdsource/CabbageStemFlea/cabbage_stem_flea_app.js';
import '../../../../static/assets/khapps/crowdsource/dashboard/crowdsource_app.js';
import '../../../../static/assets/khapps/niabcuf/irrigation/irrigation_module.js';
import '../../../../static/assets/khapps/niabcuf/niabcuf_module.js';
import '../../../../static/assets/khapps/crops/potato/potato_app.js';
import '../../../../static/assets/khapps/quakeroats/quakeroats.app.js';
import '../../../../static/assets/angular/src/main.ts';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////   Shared common Modules    ////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////   KhApps    ////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

// https://webpack.js.org/guides/dependency-management/
function importAll(r) {
  r.keys().forEach(r);
}

importAll(
  require.context(
    '../../../../static/assets/khapps2/',
    true,
    /^(?!.*\.spec\.js$).*\.(js|ts|css|less|html|png|jp(e*)g|svg|gif|woff|woff2|eot|ttf|csvg)$/
  )
);
// https://stackoverflow.com/questions/39755308/how-to-exclude-webpack-from-bundling-spec-js-files
importAll(
  require.context(
    '../../../../static/assets/khapps/',
    true,
    /^((?!(\.bootstrap\.js$|spec\.js$)).)*\.(js|ts|css|less|html|png|jp(e*)g|svg|gif|woff|woff2|eot|ttf)$/
  )
);
