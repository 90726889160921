angular.module("WeatherApp").controller('PWSDetailController',
    function ($scope, $rootScope, $resource, PWSAssignFieldsDataFactory, gateway, $mdDialog, WeatherUtilsService) {

        $scope.gateway = gateway;
        $scope.selected_station_detail = {
            name: gateway.properties.provider_reference,
            value: gateway.properties.slug
        };
        $scope.gatewayParamsList = [];
        angular.forEach($scope.gateway.properties.nodes[0].sensors, function (sensor, index) {
                $scope.gatewayParamsList.push(WeatherUtilsService.parameterUINameConvertorMap()[sensor]);
            }
        );
        $scope.gatewayParamsList = $scope.gatewayParamsList.join(", ");
        PWSAssignFieldsDataFactory.get({gateway_slug: gateway.properties.slug}, function (apiData) {
            $scope.associated_fields = apiData.data;
        });

        getStationLatestData();

        $scope.editAssignedFields = function (gateway_slug) {
            $mdDialog.show({
                controller: "AddEditPWSAssignedFieldsController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/forms/personalweatherstation/EditAssignedFieldsForm.html',
                locals: {
                    gateway_slug: gateway_slug
                },
                clickOutsideToClose: false
            })
        };

        $scope.editStation = function () {

            $mdDialog.show({
                controller: "AddEditPWSController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/forms/personalweatherstation/PWSForm.html',
                locals: {
                    weather_station_slug: weather_station.weather_station_slug
                },
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                escapeToClose: true
            });
        };

        $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
        $scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();

        function getStationLatestData() {
            var apiQueryParams = {
                "frequency": "instantaneous",
                "gateway_slug": String($scope.gateway.properties.slug),
                "data_format": "json",
                "localize": "1"
            };
            $resource('/api/weather/sensor-data/').get(apiQueryParams, function (apiData) {
                if (apiData.data) {
                    $scope.latestGatewayData = apiData.data.pop();
                }
            });
        }

    }
);
