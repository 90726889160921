angular.module("WeatherApp").factory("PWSAssignFieldsDataFactory", ["$resource", function ($resource) {
    return $resource("/api/v2.0/weather/gateway-assigned-fields/", {}, {

        get: {
            params: {gateway_slug: "@gateway_slug"},
            url: "/api/v2.0/weather/gateway-assigned-fields/:gateway_slug/",
            method: "GET"
        },
        save: {
            method: "POST"
        }

    });
}]);

