angular.module("WeatherApp").factory("FusariumMonitorFactory", ["$resource", function ($resource) {


    return $resource('/api/v1.0/weather/monitor/fusarium', {},
        {
            get: {
                params: {selected_farm: '@selected_farm', selected_field: '@selected_field'},
                url: '/api/v1.0/weather/monitor/fusarium'
            }

        }
    );

}]);