angular.module("WeatherApp").service("AfterFloweringChartConfigFactory", [function () {


    this.getChartConfig = function () {

        return {
            options: {
                chart: {
                    type: 'area'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    shared: true,
                    formatter: function () {

                        var value = this;
                        var string = '';
                        string += 'Hours where T > 15 (Deg C): ' + '<b>' + value.y + ' hours' + '</b> <br>';
                        string += 'Duration of Rainfall: ' + '<b>' + value.x + ' hours' + '</b> ';
                        return string


                    }
                },
            },

            xAxis: {
                title: {
                    text: 'Duration of Rainfall (hours)'
                },
                style: {
                    color: '#000',
                    fontWeight: 'bold'
                }
            },
            yAxis: {
                title: {
                    text: 'Hours where T >15 (Deg C)'
                },
                style: {
                    color: '#000',
                    fontWeight: 'bold'
                }
            },

            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            series: [{
                name: 'High',
                data: [[5, 170], [18, 170], [240, 0]],
                color: '#C1300D',
                enableMouseTracking: false,
                marker: {
                    enabled: false
                },
                index: 0,
                fillOpacity: 1,
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }, {
                name: 'Medium',
                data: [[0, 143], [5, 155], [48, 0]],
                color: '#C7CA09',
                enableMouseTracking: false,
                marker: {
                    enabled: false
                },
                index: 0,
                fillOpacity: 1,
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }, {
                name: 'Low',
                data: [[0, 143], [38, 0]],
                color: '#316816',
                enableMouseTracking: false,
                marker: {
                    enabled: false
                },
                index: 0,
                fillOpacity: 1,
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }]
        }
    };

}]);