angular.module("WeatherApp").controller("RegionalClimateSummaryController",
    function ($scope, $rootScope, NgTableParams, $resource, $khMessage, WeatherUtilsService) {

        setUpRegionalSummarySecondaryNavBarParameters();
        $scope.regionalClimateSummaryCards = [
            {
                kh_card_content_template: '/static/assets/khapps/weather/partials/regionalclimatesummary/RegionalClimateSummaryContent.html',
                card_flex: 100,
                show_progress_bar: true
            }];

        $rootScope.selected_region = {name: "United Kingdom", value: "United Kingdom"};
        $rootScope.initial_region_value = $rootScope.selected_region.value;
        $rootScope.region_list = [
            {name: "United Kingdom", value: "United Kingdom"},
            {name: "England", value: "England"},
            {name: "Wales", value: "Wales"},
            {name: "Scotland", value: "Scotland"},
            {name: "Northern Ireland", value: "Northern Ireland"},
            {name: "England & Wales", value: "England & Wales"},
            {name: "England North", value: "England North"},
            {name: "England South", value: "England South"},
            {name: "Scotland North", value: "Scotland North"},
            {name: "Scotland West", value: "Scotland West"},
            {name: "Scotland East", value: "Scotland East"},
            {name: "England East & North East", value: "England East & North East"},
            {name: "England North West / Wales North", value: "England North West / Wales North"},
            {name: "Midlands", value: "Midlands"},
            {name: "East Anglia", value: "East Anglia"},
            {name: "England South West/Wales South", value: "England South West/Wales South"},
            {name: "England South East/Central South", value: "England South East/Central South"}
        ];

        $scope.climateSummarySelectedParameters = [];
        $scope.availableParams = [];
        $scope.isEmptyTableDataMessage = false;


        function buildParameters(data) {
            return [{
                type: "select",
                key: "value",
                templateOptions: {
                    label: "Parameters",
                    multiple: true,
                    labelProp: "name",
                    valueProp: "value",
                    options: data
                }
            }]
        }

        $scope.loadChart = function (selected_params) {

            var chartparams = [];

            for (var i = 0; i < selected_params.length; i++) {
                chartparams.push(selected_params[i]);

            }
            $scope.climateSummarySelectedParameters = chartparams;

            buildChartOnSelectedParams($scope.climateSummarySelectedParameters);
        };

        $rootScope.getAnnualData = function (region) {
            $scope.drilldownStatus = false;
            $scope.regional_climate_selected_year = "";
            $rootScope.selected_region = region;
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.selected_parameters = {};
            $scope.chartConfiguration.lang.noData = WeatherUtilsService.MessageWeatherChartDataLoading;
            $resource('/api/weather/regional-climate').get({region: $rootScope.selected_region.value}, function (apiData) {

                if (Object.keys(apiData.data).length > 0) {

                    $scope.fullClimateData = apiData;
                    $scope.drilldownStatus = false;
                    $scope.regionalClimateSummaryCardTitle = "Annual";
                    $scope.source_attribution = $scope.fullClimateData.metadata.source_attribution;
                    var xCoordinates = Object.keys($scope.fullClimateData.data);
                    var chart_parameters = removeNonRequiredParamsFromChartParams($scope.fullClimateData.metadata.parameters);
                    $scope.availableParams = WeatherUtilsService.getParametersNamevalueDictList(chart_parameters);
                    var default_chart_parameters = ["precipitation", "avg_temperature"];

                    if ($scope.climateSummarySelectedParameters.length == 0) {

                        $scope.buildParametersForm = buildParameters($scope.availableParams);
                        $scope.climateSummarySelectedParameters = default_chart_parameters;
                        $scope.selected_parameters.value = default_chart_parameters;
                    }
                    else {
                        $scope.buildParametersForm = buildParameters($scope.availableParams);
                        $scope.selected_parameters.value = $scope.climateSummarySelectedParameters;
                    }

                    $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
                    chartDrillDown();
                    WeatherUtilsService.getChartConfig($scope, $scope.climateSummarySelectedParameters, {'categories': xCoordinates});

                    angular.forEach($scope.climateSummarySelectedParameters, function (chartParam, index) {
                            if (chartParam == 'precipitation') {
                                $scope.chartConfiguration.series[index].type = 'column';
                            }
                        }
                    );
                    var extraChartSettings = {
                        'conversionRequired': false,
                        'xyValuePush': false
                    };

                    WeatherUtilsService.pushDataInChart($scope, $scope.fullClimateData.data, $scope.climateSummarySelectedParameters, extraChartSettings);


                }
                else {
                    $scope.chartConfiguration = false;
                    $scope.$apply();
                }

            }, function errorCallback(response) {
                $scope.chartConfiguration = false;
                $khMessage.show("No data found for selected region.", 5000);
            });

        };

        buildChartFirstTime();

        function buildChartFirstTime() {
            $rootScope.getAnnualData($rootScope.selected_region);
        }

        function removeNonRequiredParamsFromChartParams(chartParams) {
            chartParams = WeatherUtilsService.removeElementFromArray(chartParams, 'rain_day1mm');
            return chartParams
        }

        function buildChartOnSelectedParams(chart_parameters) {
            var xCoordinates = Object.keys($scope.fullClimateData.data);
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            var extraChartSettings = {
                'conversionRequired': false,
                'xyValuePush': false
            };


            WeatherUtilsService.getChartConfig($scope, chart_parameters, {'categories': xCoordinates});

            if ($scope.drilldownStatus) {
                addCustomButtonToChart();
            }
            chartDrillDown();
            WeatherUtilsService.pushDataInChart($scope, $scope.fullClimateData.data, chart_parameters, extraChartSettings);

        }

        function chartDrillDown() {


            $scope.chartConfiguration.chart = {
                zoomType: 'xy',
                events: {
                    drilldown: function (e) {
                        var year = e.point.drilldown;
                        if (!e.seriesOptions) {
                            $scope.chartConfiguration.series = [];
                            $scope.$apply();

                        }
                        getMonthlyClimateData(year);

                    }
                }

            };

        }

        function getMonthlyClimateData(year) {

            $scope.drilldownStatus = true;
            $scope.isEmptyTableDataMessage = false;
            $scope.monthlyRegionalClimateParameters = new NgTableParams({}, {dataset: {}});
            $scope.regional_climate_selected_year = "(" + year + ")";
            $scope.regionalClimateSummaryCardTitle = "Monthly";
            $scope.chartConfiguration.noData = WeatherUtilsService.MessageWeatherChartDataLoading;
            $resource('/api/weather/regional-climate').get({
                region: $rootScope.selected_region.value,
                year: year
            }, function (apiData) {
                $scope.source_attribution = apiData.metadata.source_attribution;
                var data_seasonal = apiData.metadata.data_seasonal;
                var data_set = apiData.data;
                $scope.monthlyRegionalClimateParameters = new NgTableParams({}, {dataset: data_seasonal});
                $scope.isEmptyTableDataMessage = data_seasonal === 0;

                if (apiData.length === 0) {
                    $scope.chartConfiguration = {};
                    $khMessage.show("Monthly regional summary not found", 5000);
                }
                else {

                    var xCoordinates = Object.keys(data_set);
                    $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
                    var extraChartSettings = {
                        'conversionRequired': false,
                        'xyValuePush': false
                    };

                    WeatherUtilsService.getChartConfig($scope, $scope.climateSummarySelectedParameters, {'categories': xCoordinates});
                    WeatherUtilsService.pushDataInChart($scope, data_set, $scope.climateSummarySelectedParameters, extraChartSettings);
                    addCustomButtonToChart();


                }
            })

        }

        function addCustomButtonToChart() {
            $scope.chartConfiguration.exporting = {

                buttons: {

                    contextButton: {
                        enabled: false
                    },

                    customButton: {
                        align: 'left',
                        x: 40,
                        y: 17,
                        text: 'Back',

                        theme: {
                            fill: '#ddd',
                            stroke: '#888'

                        },

                        onclick: function (e) {
                            if (!e.seriesOptions) {
                                $scope.chartConfiguration.series = [];
                            }
                            buildChartFirstTime();
                            $scope.$apply();
                        }
                    }
                }
            }

        }

        function setUpRegionalSummarySecondaryNavBarParameters() {
            $rootScope.show_region = true;
            $rootScope.showFarmDropDown = false;
            $rootScope.showFieldDropDown = false;
            $rootScope.showPWSDropDown = false;

            $rootScope.show_weather_station = false;
            $rootScope.pageTitle = "AgroMet";
            $rootScope.page_id = "weather";
            $rootScope.sub_app_page_id = "regional_summary";
            $rootScope.showDataLoggerSubLinks = false;
            $rootScope.show_override = false;
            $rootScope.showWeatherLandingCardViewSubLinks = false;
            $rootScope.showSensorListMainCardViewSubLinks = false;

        }
    });
