angular.module("WeatherApp").controller("PWSController",
    function ($scope, $rootScope, $resource, PWSFactory, NgTableParams, $mdDialog, $khMessage, FarmFiltersService,
              WeatherUtilsService, WeatherFunctionHelperService, GenericMapService) {

        WeatherFunctionHelperService.setUpSensorListSecondaryNavBarParameters($rootScope);

        $rootScope.currentDataLoggerView = "personalWeatherStation";
        $rootScope.weatherNavItem = "pws";
        $scope.isEmptyTableDataMessage = false;
        $scope.enableStationWeatherDataExportButton = true;

        $scope.searchStationName = {
            'properties.provider_reference': {
                id: 'text',
                placeholder: 'Filter by name'
            }
        };
        $scope.searchStationMakeModel = {
            'properties.make_model': {
                id: 'text',
                placeholder: 'Filter by make model'
            }
        };

        $scope.searchDistance = {
            'distance': {
                id: 'text',
                placeholder: 'Filter by distance'
            }
        };

        $scope.searchLocation = {
            'properties.location_desc': {
                id: 'text',
                placeholder: 'Filter by location'
            }
        };

        $scope.searchLatLong = {
            'geometry.coordinates': {
                id: 'text',
                placeholder: 'Filter by latitude, longitude'
            }
        };

        $scope.searchStationAccessType = {
            'properties.access_type': {
                id: 'text',
                placeholder: 'Filter by type'
            }
        };
        $scope.personalWeatherStationAndSensorCards = [
            {
                card_title: 'Weather Stations',
                kh_card_content_template: '/static/assets/khapps/weather/partials/personalweatherstation/PWSList.html',
                card_flex: 100,
                show_progress_bar: false
            }
        ];

        $scope.disableEditStation = function (station_name) {
            return station_name !== 'Skye-Minimet';
        };

        $scope.showDetailsOfLogger = function (gateway) {
            $rootScope.gateway = gateway;
            $mdDialog.show({
                controller: 'PWSDetailController',
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/personalweatherstation/PWSDetail.html',
                locals: {
                    gateway: gateway
                },
                clickOutsideToClose: true
            })
        };

        $scope.editAssignedFields = function (gateway_slug, name) {
            $rootScope.selected_station_detail = {
                name: name,
                value: gateway_slug

            };
            if (FarmFiltersService.getFarms().length > 0) {
                $mdDialog.show({
                    controller: "AddEditPWSAssignedFieldsController",
                    skipHide: true,
                    multiple: true,
                    templateUrl: '/static/assets/khapps/weather/partials/forms/personalweatherstation/EditAssignedFieldsForm.html',
                    clickOutsideToClose: false
                });
            }
            else {
                $khMessage.show("User does not have any Farms.", 5000);
            }

        };

        $scope.edit_pws = function (station_slug) {
            $mdDialog.show({
                controller: "AddEditPWSController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/forms/personalweatherstation/PWSForm.html',
                locals: {
                    weather_station_slug: station_slug
                },
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                escapeToClose: true,
                fullscreen: true
            });
        };

        $scope.OpenStationExportDialog = function (gateway_slug) {
            var dataFromParentController = {};
            dataFromParentController["slug"] = gateway_slug;
            $mdDialog.show({
                controller: "PWSDataExportController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/abstract/WeatherDataExport.html',
                parent: angular.element(document.body),
                locals: {
                    dataFromParentController: dataFromParentController
                },
                clickOutsideToClose: false
            });
        };

        $scope.getParameterRow = function (params) {
            var formattedParams = [];
            angular.forEach(params, function (param, index) {
                formattedParams.push(WeatherUtilsService.parameterUINameConvertorMap()[param])
            });
            return formattedParams.join(", ");
        };

        $scope.checkForClearing = function (search_query) {
            if (search_query.length !== 0) {
                $scope.clearFilter();
            }
        };
        $scope.searchPWS = function (search_query) {
            if (search_query.length !== 0) {
                $scope.clearFilter();
            }
        };
        $scope.clearFilter = function () {
            $scope.searched = false;
            $scope.no_access_message = false;
            $scope.search_query = null;
            $scope.message = null;
        };

    });
