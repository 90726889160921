angular.module('user_notification_preferences').factory("UserNotificationSubscriptionHelperFactory", [


    function (UserNotificationSubscriptionHelperFactory) {

        /**
         * =======================================================================================
         * Two types of form config available.
         * 1. newNotificationModelForm (for adding new notifications)
         * 2. existingNotificationModelForm (for editing existing notifications)
         *
         *  add object type here for new object (farm, station etc) and
         *  subsequently add form config for both 1 and 2 type.
         *
         *  Abstract config will remain common for all object type
         *  both gereric and specific object type are combined using
         *  objectTypeForm.push.apply(objectTypeForm, genericTypeForm)
         * =======================================================================================
         */



        var ObjectKeyFarm = "Farm";
        var ObjectKeyField = "FarmField";
        var ObjectKeyPWS = "PersonalWeatherStation";


        function getFormConfigMap(inputDict, $scope) {
            if (inputDict["formFor"] == "existingData") {
                return existingNotificationModelFormSetup(inputDict)
            } else {
                return newNotificationModelFormSetup(inputDict, $scope)
            }
        }

        function existingNotificationModelFormSetup(inputDict) {

            var abstractModelFormConfig = [
                {
                    className: "flex-35",
                    type: "select",
                    key: "notification_types",
                    templateOptions: {
                        required: true,
                        disabled: true,
                        label: "Notification types",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "value",
                        options: inputDict["userNotificationConstants"].notification_types[inputDict["object_key"]]
                    }
                },
                {
                    className: "flex-25",
                    type: "select",
                    key: "channels",
                    templateOptions: {
                        required: true,
                        label: "Notification modes",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "value",
                        options: inputDict["userNotificationConstants"].channels
                    }
                },
                {
                    className: "flex-25",
                    type: "select",
                    key: "languages",
                    templateOptions: {
                        required: true,
                        label: "Language",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "value",
                        options: inputDict["userNotificationConstants"].languages
                    }
                },
                {
                    className: "flex-15",
                    key: 'is_valid',
                    type: 'switch',
                    defaultValue: true,
                    templateOptions: {
                        align: 'flex-end'
                    },
                    hideExpression: false.toString()
                }
                ];

            var map = {};
            map["abstract"] = abstractModelFormConfig;
            map[ObjectKeyField] = fieldModelFormConfig();
            map[ObjectKeyPWS] = pwsModelFormConfig();
            map[ObjectKeyFarm] = farmModelFormConfig();

            return map
        }

        function newNotificationModelFormSetup(inputDict, $scope) {
            var abstractModelFormConfig = [
                {
                    className: "flex-100",
                    type: "searchable_select",
                    key: "notification_types",
                    templateOptions: {
                        required: true,
                        disabled: false,
                        label: "Notification types",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "value",
                        options: inputDict["availableNotificationTypes"]
                    }
                },
                {
                    className: "flex-100",
                    type: "searchable_select",
                    key: "channels",
                    templateOptions: {
                        required: true,
                        label: "Notification modes",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "value",
                        options: inputDict["userNotificationConstants"].channels
                    }

                },
                {
                    className: "flex-100",
                    type: "searchable_select",
                    key: "languages",
                    templateOptions: {
                        required: true,
                        label: "Language",
                        multiple: true,
                        labelProp: "name",
                        valueProp: "value",
                        options: inputDict["userNotificationConstants"].languages
                    }
                },
                {
                    key: 'is_valid',
                    type: 'switch',
                    templateOptions: {},
                    hideExpression: true.toString()
                }];

            var map = {};
            map["abstract"] = abstractModelFormConfig;
            map[ObjectKeyField] = fieldModelFormConfig();
            map[ObjectKeyPWS] = pwsModelFormConfig();
            map[ObjectKeyFarm] = farmModelFormConfig();

            return map
        }

        function fieldModelFormConfig() {
            return [];
        }

          function pwsModelFormConfig() {
            return [];
        }
          function farmModelFormConfig() {
            return [];
        }

        return {
            getModelFormConfig: function (inputDict, $scope) {

                var modelFormConfigMap = getFormConfigMap(inputDict, $scope);
                var ObjectKeyForm = modelFormConfigMap[inputDict["object_key"]];

                var genericTypeForm = modelFormConfigMap["abstract"];
                ObjectKeyForm.push.apply(ObjectKeyForm, genericTypeForm);
                return ObjectKeyForm
            }
        };
    }]);