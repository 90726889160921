angular.module('WeatherApp').controller('PublicDataController',
    function ($scope, $rootScope, EADataResource, FieldNamesFactory, NgTableParams, $mdDialog, $khMessage, $khPulse,
        leafletData, FarmFiltersService, WeatherUtilsService, GenericMapService, WeatherFunctionHelperService) {
        WeatherFunctionHelperService.setUpSensorListSecondaryNavBarParameters($rootScope);
        var mapid = 'landingMap';

        $scope.isEmptyTableDataMessage = false;
        $scope.publicDataCards = [
            {
                card_title: 'Public Stations',
                kh_card_content_template: '/static/assets/khapps/weather/partials/public_data_sources/PublicSourcesList.html',
                card_flex: 100,
                show_progress_bar: false
            }
        ];

        $rootScope.currentDataLoggerView = 'publicDataSources';

        $scope.searchProviderReference = {
            'properties.provider_reference': {
                id: 'text',
                placeholder: 'Filter by name'
            }
        };

        $scope.searchProvider = {
            'properties.provider': {
                id: 'text',
                placeholder: 'Filter by provider'
            }
        };

        $scope.searchLatLong = {
            'geometry.coordinates': {
                id: 'text',
                placeholder: 'Filter by latitude, longitude'
            }
        };

        $scope.searchDistance = {
            'distance': {
                id: 'text',
                placeholder: 'Filter by Distance'
            }
        };

        $scope.searchConfiguration = {
            make_model: {
                id: 'text',
                placeholder: 'Filter by configuration'
            }
        };

        $scope.draw_weather_station = function () {
            $scope.weather_station_marker = true;
            add_marker();
        };

        $scope.draw_public_data_source = function () {
            $scope.public_data_source_marker = true;
            add_marker();
        };


        function add_marker() {
            leafletData.getMap(mapid).then(function (map) {
                var marker = new L.Draw.Marker(map, {});
                marker.enable();
            });
        }

        $scope.showDetailsPDS = function (pds) {
            $rootScope.pds = pds;
            $mdDialog.show({
                controller: 'PublicDataSourceDetailController',
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/weather/partials/public_data_sources/PDSDetail.html',
                locals: {
                    pds: pds
                },
                clickOutsideToClose: true
            });
        };

        $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();

    });
