angular.module("WeatherApp").run(["$http", "$route", "$rootScope", "$timeout", "$window", "formlyConfig", "UserSettingsFactory",
    "DateTimeHelperFactory","$mdDialog", 'leafletData','GenericMapService',
    function ($http, $route, $rootScope, $timeout, $window, formlyConfig, UserSettingsFactory, DateTimeHelperFactory, $mdDialog, leafletData, GenericMapService) {


        UserSettingsFactory.get(function (result) {
            $rootScope.user_settings = result;
            $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);
            $rootScope.userSettingDateTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToAngular($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);


            $rootScope.userSettingDateFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);
            $rootScope.userSettingDateTimeFormatMoment = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($rootScope.user_settings.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
        });

        // Notification subscriptions for field, stations
        $rootScope.ObjectNotificationSubscription = function (object_key, object_slug, objectName) {
            $mdDialog.show({
                controller: "UserNotificationSubscriptionController",
                templateUrl: '/static/assets/khapps/user_notification_preferences/UserNotificationSubscription.html',
                parent: angular.element(document.body),
                locals: {
                    configDict: {
                        "object_key": object_key,
                        "object_slug": object_slug,
                        "name": objectName
                    }

                },
                clickOutsideToClose: false
            })
        };


        // share objects
        $rootScope.shareObject = function (object_type, object_slug) {
            $mdDialog.show({
                controller: "ShareWithAddEditController",
                templateUrl: "/static/assets/khapps/profiles/share_with/partials/share_with_form.html",
                parent: angular.element(document.body),
                animation: true,
                scope: $scope,
                preserveScope: true,
                clickOutsideToClose: false,
                locals: {object_slug: object_slug, object_type: object_type}
            });
        };

        $rootScope.in_app_links_template = '/static/assets/khapps/weather/partials/includes/weather_navbar_links.html';
        $rootScope.view_as_template = "/static/assets/khapps/weather/partials/includes/weather_navbar_views.html";
        $rootScope.select_filters_template = "/static/assets/khapps/weather/partials/includes/weather_navbar_filters.html";

        $rootScope.tableview = false;
        $rootScope.mapview = false;
        $rootScope.cardview = true;
        $rootScope.activeView = "cardview";

        $rootScope.views = [
            {name: 'Map View', value: 'mapview', icon: 'google-maps'},
            {name: 'Card View', value: 'cardview', icon: 'view-grid'},
        ];

        $rootScope.changeView = function (view) {
            $rootScope.activeView = view;
            switch (view) {
                case "mapview":
                    var mapid = 'landingMap';
                    $rootScope.tableview = false;
                    $rootScope.mapview = true;
                    $rootScope.cardview = false;
                    GenericMapService.reset_map_view($rootScope, leafletData, $window, $timeout, mapid);
                    break;
                case "tableview":
                    $rootScope.tableview = true;
                    $rootScope.mapview = false;
                    $rootScope.cardview = false;
                    break;
                case "cardview":
                    $rootScope.tableview = false;
                    $rootScope.mapview = false;
                    $rootScope.cardview = true;
                    break;
            }
        };
    }]
);
