angular.module("WeatherApp").controller('WeatherLandingFilterController', ["$scope", "$rootScope", "$timeout", "FiveDayForecastDataFactory",

    function ($scope, $rootScope, $timeout, FiveDayForecastDataFactory) {

        $scope.selected_data_type = null;
        $scope.selected_farm = null;
        $scope.selected_field = null;
        $scope.selected_action = null;

        $scope.dataTypes = [
            {'id': 'Actual', 'name': 'Actual'},
            {'id': 'Forecast-9m', 'name': 'Forecast-9m'},
            {'id': 'Forecast-5d', 'name': 'Forecast-5d'}
        ];

        $scope.farms = [
            {'id': 'bsjgffqw', 'name': 'Warwic'},
            {'id': 'hhh', 'name': 'Bainton'}
        ];

        $scope.fields = [
            {'id': 'Field 1', 'name': 'Field 1'},
            {'id': 'Field 2', 'name': 'Field 2'}
        ];

        $scope.actions = [
            {'id': 'View', 'name': 'View'},
            {'id': 'Download', 'name': 'Download'}
        ];

        getForecastData();
        getForecastChartConfig();

        function getForecastData() {
            $scope.forecastData = FiveDayForecastDataFactory.getFiveDayForecastData();
            $scope.dates = Object.keys($scope.forecastData);
        }

        $scope.loadHourlyData = function (date) {
            var hourlyData = $scope.forecastData[date].hourly;
            buildChart(hourlyData);
        };

        $scope.getdailyData = function (date) {
            return $scope.forecastData[date].daily
        };

        function getForecastChartConfig() {
            $scope.chartConfiguration = WeatherUtilsService.getChartConfig(['precipitation', 'wind_speed', 'temperature'], {type: 'datetime'});
        }

        function buildChart(hourlyData) {
            var extraChartSettings = {
                'conversionRequired': true,
                'datetimeFormat': 'YYYY-MM-DDTHH:mm:ss',
                'xyValuePush': true
            };
            var chartParams = ['precipitation', 'wind_speed', 'temperature'];
            WeatherUtilsService.pushDataInChart($scope, hourlyData, chartParams, extraChartSettings);
        }

    }]);