angular.module("WeatherApp").service('BeforeFloweringChartConfigFactory', [function () {


        this.getChartConfig = function () {
            return {
                options: {
                    chart: {
                        type: 'area'
                    },
                    title: {
                        text: ''
                    },

                    tooltip: {
                        shared: true,
                        formatter: function () {

                            var value = this;
                            var string = '';
                            string += 'Hours where T >15(Deg C): ' + '<b>' + value.x + ' hours' + '</b> <br>';
                            string += 'Hours where T >15(Deg C) and RH >90%: ' + '<b>' + value.y + ' hours' + '</b> ';
                            return string


                        }
                    }


                },

                xAxis: {
                    title: {
                        text: 'Hours where T > 15 (Deg C)'
                    },
                    style: {
                        color: '#000',
                        fontWeight: 'bold'
                    }
                },
                yAxis: {
                    title: {
                        text: 'Hours where T >15 (Deg C) and RH >90%'
                    },
                    style: {
                        color: '#000',
                        fontWeight: 'bold'
                    }
                },

                plotOptions: {
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 1,
                        marker: {
                            lineWidth: 1,
                            lineColor: '#666666'
                        }
                    }
                },
                series: [{
                    name: 'High',
                    data: [[85, 200], [128, 200], [168, 200]],
                    color: '#C1300D',
                    enableMouseTracking: false,
                    marker: {
                        enabled: false
                    },
                    index: 0,
                    fillOpacity: 1,
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }

                }, {
                    name: 'Medium',
                    data: [[0, 145], [85, 90], [128, 65], [168, 70]],
                    color: '#C7CA09',
                    enableMouseTracking: false,
                    marker: {
                        enabled: false
                    },
                    index: 0,
                    fillOpacity: 1,
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                }, {
                    name: 'Low',
                    data: [[0, 48], [85, 48], [168, 48]],
                    color: '#316816',
                    enableMouseTracking: false,
                    marker: {
                        enabled: false
                    },
                    index: 0,
                    fillOpacity: 1,
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                }
                ]

            }
        }
    }]
);