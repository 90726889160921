angular.module('user_notification_preferences').factory("UserNotificationSubscriptionConstantsFactory", ["$resource", function ($resource) {
    return $resource('/api/v2.0/notification/user_notification_preferences/notification_subscription_constants/', {}, {

        get: {
            params: {},
            url: '/api/v2.0/notification/user_notification_preferences/notification_subscription_constants/',
            method: 'GET'
        }

    });
}]);


