angular.module("WeatherApp").controller("AddEditPWSController",
    function ($scope, $rootScope, $mdDialog, leafletData, $resource, PWSFactory, gateway_slug,
              $khMessage, $khPulse, $anchorScroll, WeatherUtilsService, GenericMapService, NgTableParams) {

        $scope.farm = $rootScope.farm;
        var inc_id;
        var mapid = 'landingMap';
        var padZeros = function padZeros(number, digits) {
            return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
        };

        initiate();

        function initiate() {
            loadPWSConstants(configurePWSSetUpForm);
        }

        var PWSFormlyFields = function ($scope, updatePWS) {
                return [
                    {
                        className: "flex-50",
                        type: "input",
                        key: "provider_reference",
                        id: "provider_reference",
                        templateOptions: {
                            label: "Name",
                            required: true
                        }
                    },
                    {
                        className: "flex-25",
                        type: 'select',
                        key: "make_model",
                        id: "make_model",
                        defaultValue: '',
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option[to.valueProp] as option in to.options',
                            label: "Type",
                            required: true,
                            options: $scope.pwsTypes,
                            valueProp: 'value',
                            labelProp: 'name'
                        }
                    },
                    {
                        className: "flex-25",
                        type: 'select',
                        key: "upload_method",
                        id: "upload_method",
                        defaultValue: 'Manual',
                        templateOptions: {
                            label: "Upload Type",
                            required: true,
                            options: [
                                {
                                    'name': 'Automated-HTTP',
                                    'value': 'Automated-HTTP'
                                },
                                {
                                    'name': 'Automated-FTP',
                                    'value': 'Automated-FTP'
                                },
                                {
                                    'name': 'Manual',
                                    'value': 'Manual'
                                }
                            ],
                            valueProp: 'value',
                            labelProp: 'name'
                        },
                        expressionProperties: {
                            'templateOptions.disabled': function ($viewValue, $modelValue, scope) {
                                return (scope.model.make_model === 'CUSTOM');
                            }

                        },
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.make_model === 'CUSTOM') {
                                        $scope.$parent.model.upload_method = 'Manual'
                                    }
                                }
                            });


                        }
                    },
                    {
                        className: "flex-100",
                        key: "parameters",
                        type: 'select',
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                            multiple: true,
                            label: "Parameters",
                            valueProp: 'value',
                            labelProp: 'name',
                            placeholder: 'Select parameters',
                            required: true,
                            options: $scope.baseParamsNamevalueDictList
                        },
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {

                                    if (!$rootScope.updatePWS) {
                                        $scope.model.parameters = $rootScope.default_selected_parmeters[$scope.$parent.model.make_model];
                                    }
                                }
                            });


                        },
                        validators: {
                            implementers: {
                                expression: function (viewValue, modelValue) {
                                    if (modelValue) {
                                        return modelValue.length > 0;
                                    }
                                    else {
                                        return false
                                    }
                                }
                            }
                        }
                    },
                    {
                        className: "flex-50",
                        type: "input",
                        key: "location_desc",
                        id: "location_desc",
                        templateOptions: {
                            label: "Location Description",
                            required: false,
                            maxlength: 50
                        }
                    },
                    {
                        className: "flex-25",
                        type: "input",
                        key: "latitude",
                        id: "latitude",
                        templateOptions: {
                            label: "Latitude",
                            required: true,
                            maxlength: 11,
                            disabled: updatePWS
                        }
                    },
                    {
                        className: "flex-25",
                        type: "input",
                        key: "longitude",
                        id: "longitude",
                        templateOptions: {
                            label: "Longitude",
                            required: true,
                            maxlength: 11,
                            disabled: updatePWS
                        }
                    },
                    {
                        className: "flex-50",
                        key: 'gateway_id',
                        type: 'input',
                        templateOptions: {
                            required: false,
                            type: 'input',
                            label: 'Serial Number',
                            placeholder: 'Station unique serial number'
                        }
                    },
                    {
                        className: "flex-25",
                        type: "select",
                        key: "frequency",
                        id: "frequency",
                        defaultValue: "15 MIN",
                        templateOptions: {
                            label: "Frequency",
                            options: [
                                {
                                    'name': '5 MIN',
                                    'value': '5 MIN'
                                },
                                {
                                    'name': '15 MIN',
                                    'value': '15 MIN'
                                },
                                {
                                    'name': '30 MIN',
                                    'value': '30 MIN'
                                },
                                {
                                    'name': '1 HOUR',
                                    'value': 'HOURLY'
                                }
                            ],
                            valueProp: 'value',
                            labelProp: 'name'
                        },
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-HTTP" || scope.model.upload_method === "Automated-FTP");
                            }
                        },
                        hideExpression: 'model.upload_method == "Manual"',
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.frequency = null
                                    }
                                }
                            });


                        }
                    },
                    {
                        className: "flex-25 padding-left-20",
                        type: "checkbox",
                        key: "is_public",
                        defaultValue: false,
                        templateOptions: {
                            label: 'Public'
                        }

                    },
                    {
                        className: "flex-50",
                        type: "input",
                        key: "http_username",
                        id: "http_username",
                        templateOptions: {
                            label: "Device ID"
                        },
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-HTTP");
                            }
                        },
                        hideExpression: '!(model.upload_method == "Automated-HTTP")',
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.http_username = null
                                    }
                                }
                            });


                        }
                    },
                    {
                        className: "flex-50",
                        key: 'http_password',
                        type: 'input',
                        templateOptions: {
                            type: 'password',
                            label: 'Password',
                            minlength: 3
                        },
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-HTTP");
                            }
                        },
                        hideExpression: '!(model.upload_method == "Automated-HTTP")',
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.http_password = null
                                    }
                                }
                            });


                        }
                    },

                    {
                        className: "flex-100",
                        key: 'ftp_host',
                        type: 'input',
                        templateOptions: {
                            type: 'input',
                            label: 'FTP Host'
                        },
                        hideExpression: '!(model.upload_method == "Automated-FTP")',
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-FTP");
                            }
                        },
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.ftp_host = null
                                    }
                                }
                            });


                        }
                    },
                    {
                        className: "flex-50",
                        type: "input",
                        key: "ftp_username",
                        id: "ftp_username",
                        templateOptions: {
                            label: "Username"
                        },
                        hideExpression: '!(model.upload_method == "Automated-FTP")',
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-FTP");
                            }
                        },

                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.ftp_username = null
                                    }
                                }
                            });


                        }
                    },
                    {
                        className: "flex-50",
                        key: 'ftp_password',
                        type: 'input',
                        templateOptions: {
                            type: 'password',
                            label: 'Password',
                            minlength: 3
                        },
                        hideExpression: '!(model.upload_method == "Automated-FTP")',
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-FTP");
                            }
                        },
                        controller: function ($scope, $rootScope) {
                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.ftp_password = null
                                    }
                                }
                            });


                        }
                    },
                    {
                        className: "flex-50",
                        key: 'remote_directory',
                        type: 'input',
                        templateOptions: {
                            label: 'Remote Directory'
                        },
                        hideExpression: '!(model.upload_method == "Automated-FTP")',
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-FTP");
                            }
                        },
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.remote_directory = null
                                    }
                                }
                            });


                        }
                    },
                    {
                        className: "flex-50",
                        key: 'remote_folder',
                        type: 'input',
                        templateOptions: {
                            label: 'Remote Folder'
                        },
                        hideExpression: '!(model.upload_method == "Automated-FTP")',
                        expressionProperties: {

                            'templateOptions.required': function ($viewValue, $modelValue, scope) {
                                return (scope.model.upload_method === "Automated-FTP");
                            }
                        },
                        controller: function ($scope, $rootScope) {

                            $scope.$watch("$parent.model.make_model", function handleChange(newStationChoice, oldStationChoice) {
                                if (newStationChoice) {
                                    if ($scope.$parent.model.upload_method === 'Manual') {
                                        $scope.$parent.model.remote_folder = null
                                    }
                                }
                            });


                        }
                    }

                ]
            }
        ;

        function loadPWSConstants(callback) {
            $resource('/api/weather/parameter-constants').get(function (PWSConstantAPIData) {
                $scope.pwsTypes = PWSConstantAPIData.gateways_type;
                $rootScope.default_selected_parmeters = PWSConstantAPIData.default_selected_parameters;
                $rootScope.pwsParametersOptions = PWSConstantAPIData.gateways_parameter;
                $rootScope.unitsGroupbyMeasureOf = PWSConstantAPIData.units_group_by_measure;
                $rootScope.parameterMeasureOf = PWSConstantAPIData.parameters_measure_of;
                $rootScope.measurementUnitMapping = PWSConstantAPIData.measurement_unit_mapping;
                callback();
            });
        }

        function configurePWSSetUpForm() {
            if (gateway_slug) {
                $rootScope.updatePWS = true;
                PWSFactory.get({gateway_slug: gateway_slug}, function (PWSAPIData) {
                        $rootScope.pws_data = {};
                        $rootScope.pws_data = arrangePWSDataToPopulateInForm(PWSAPIData.data);
                        $scope.marker_model = $rootScope.pws_data;
                        if (!$scope.marker_model.nodes) {
                            $scope.marker_model.nodes = [];
                        }
                        inc_id = $scope.marker_model.nodes.length
                    }
                );
            }
            else {
                $rootScope.updatePWS = false;
                $scope.marker_model = $rootScope.pws_data;
                $scope.marker_model.nodes = [];
                inc_id = 0;
            }
            setUpForm();
        }

        Array.prototype.difference = function (e) {
            return this.filter(function (i) {
                return e.indexOf(i) < 0;
            });
        };

        function setUpForm() {
            $scope.baseParamsNamevalueDictList = WeatherUtilsService.getParametersNamevalueDictList($rootScope.pwsParametersOptions);
            $scope.PWSFormlyFields = PWSFormlyFields($scope, $rootScope.updatePWS);

        }

        $scope.$watch("marker_model.upload_method", function handleChange(newParameters, oldParameters) {
            if (newParameters === 'Automated-FTP' || newParameters === 'Manual') {
                $scope.marker_model.nodes = [];
            }
        });

        $scope.save_marker = function (formModelData) {

            if (gateway_slug) {
                updatePWS(formModelData);
            } else {
                createPWS(formModelData);
            }
        };

        function createPWS(formModelData) {
            $mdDialog.hide();
            arrangePWSDataBeforeSaving(formModelData, $rootScope.measurementUnitMapping);
            PWSFactory.save(formModelData, function (success_data) {
                var weather_station = success_data.data;
                if (weather_station) {
                    if (typeof($rootScope.pwsdata_geoJsonLayer) === 'undefined' || $rootScope.leaflet_pws_layer_id === -9999) {
                        GenericMapService.loadPWSData($khPulse, $rootScope, leafletData, mapid, PWSFactory, $khMessage, NgTableParams, $scope);
                    }
                    else {
                        $rootScope.pwsdata_geoJsonLayer.features.push(weather_station);
                        $rootScope.pwsdata_clusterLayer.addLayer(createGeoJsonFieldSensorLayer(weather_station.features[0], $rootScope, leafletData, mapid));
                    }
                }
                $khMessage.show("Weather station created successfully.", 5000);
            }, function (error_data) {
                if (error_data) {
                    var message = "Failed to create weather station.";
                    $khMessage.show(message);

                }
            });
        }


        function updatePWS(formModelData) {
            $mdDialog.hide();
            arrangePWSDataBeforeSaving(formModelData, $rootScope.measurementUnitMapping);
            PWSFactory.update({gateway_slug: gateway_slug}, formModelData, function (success_data) {
                var weather_station = success_data.data;
                if (weather_station) {
                    $scope.replacePWSFromGeojson(weather_station.geometry, weather_station.properties);
                }
                $khMessage.show("Weather station updated successfully.", 5000);
            }, function (error) {
                var message = "Failed to update weather station.";
                $khMessage.show(message)

            });
        }

        $scope.replacePWSFromGeojson = function (geometry, properties) {
            for (var i = 0; i < $rootScope.pwsdata_geoJson.features.length; i++) {
                if (properties.slug === $rootScope.pwsdata_geoJson.features[i].properties.slug) {
                    $rootScope.pwsdata_geoJson.features[i].properties = properties;
                    $rootScope.pwsdata_geoJson.features[i].geometry = geometry;
                    return i;
                }
            }
        };


        $scope.cancelAddEditFeature = function () {
            $mdDialog.cancel();
        };

        $scope.addEditPWSSensorGroup = function (index) {
            var sensorGroup;
            if (index === undefined) {
                inc_id += 1;
                if ($scope.marker_model.provider_reference) {
                    sensorGroup = {node_id: $scope.marker_model.provider_reference + '-' + padZeros(inc_id, 3)};
                }
                else {
                    sensorGroup = {};
                }
            }
            else {
                sensorGroup = $scope.marker_model.nodes[index];
            }
            var existingSourceNodeId = [], i;
            for (i = 0; i < $scope.marker_model.nodes.length; i++) {
                existingSourceNodeId.push($scope.marker_model.nodes[i].node_id)
            }
            var reservedAPIParseKeys = [];
            for (i in $rootScope.measurementUnitMapping) {
                reservedAPIParseKeys.push($rootScope.measurementUnitMapping[i].api_parse_key)
            }

            $mdDialog.show({
                controller: 'AddPWSSensorGroupController',
                skipHide: true,
                multiple: true,
                controllerAs: "dl",
                parent: angular.element(document.querySelector('#PWSForm')),
                templateUrl: '/static/assets/khapps/weather/partials/personalweatherstation/AddPWSSensorGroup.html',
                locals: {
                    sensorGroup: sensorGroup,
                    existingSourceNodeId: existingSourceNodeId,
                    reservedAPIParseKeys: reservedAPIParseKeys
                },
                clickOutsideToClose: false
            }).then(function (successData) {
                if (index !== undefined) {

                    $scope.marker_model.nodes[index] = successData
                }
                else {
                    if ($scope.marker_model.nodes.filter(function (obj) {
                            return obj.node_id === successData.node_id
                        }).length !== 0) {
                        $khMessage.show("Failed: Sensor group name already exists.", 5000);
                    }
                    else {
                        $scope.marker_model.nodes.push(successData);
                    }
                }
                $anchorScroll("cancelButton");
            }, function (err) {
                inc_id -= 1;
            })
        };

        $scope.deletePWSSensorGroup = function (index) {
            $scope.marker_model.nodes.splice(index, 1);
        };

        function arrangePWSDataBeforeSaving(pws_data, measurementUnitMapping) {
            var AUTOMATED_FTP = 'Automated-FTP';
            var AUTOMATED_HTTP = 'Automated-HTTP';
            var MANUAL = 'Manual';

            if (pws_data.nodes && pws_data.nodes.length !== 0) {
                for (var node_id in pws_data.nodes) {
                    pws_data.nodes[node_id].environment = "Open";
                    pws_data.nodes[node_id].is_default = false;
                    for (var param_id in pws_data.nodes[node_id].sensors) {
                        delete pws_data.nodes[node_id].sensors[param_id].parameter_list;
                        delete pws_data.nodes[node_id].sensors[param_id].unit_list;
                    }
                }
            }
            if (!pws_data.nodes) {
                pws_data.nodes = [];
            }
            var default_node = {
                node_id: "Default",
                is_default: true,
                environment: "Open",
                slug: pws_data.default_node_slug,
                sensors: []
            };
            if (pws_data.upload_method === AUTOMATED_HTTP) {
                for (var param_id = 0; param_id < pws_data.parameters.length; param_id++) {
                    var parameter_detail = {parameter: pws_data.parameters[param_id]};
                    if (measurementUnitMapping.hasOwnProperty(pws_data.parameters[param_id])) {
                        if (measurementUnitMapping[pws_data.parameters[param_id]].api_parse_key) {
                            parameter_detail.measurement_key = measurementUnitMapping[pws_data.parameters[param_id]].api_parse_key;
                        }
                        if (measurementUnitMapping[pws_data.parameters[param_id]].unit) {
                            parameter_detail.unit = measurementUnitMapping[pws_data.parameters[param_id]].unit;
                        }
                    }
                    default_node.sensors.push(parameter_detail);
                }
            }
            else {
                for (var param_id = 0; param_id < pws_data.parameters.length; param_id++) {
                    default_node.sensors.push({
                        parameter: pws_data.parameters[param_id]
                    });
                }
            }

            pws_data.nodes.push(default_node);
            delete pws_data.parameters;
            if (pws_data.upload_method === AUTOMATED_HTTP) {

                pws_data.configurations = [
                    {
                        'key': 'http_username',
                        'value': pws_data.http_username
                    },
                    {
                        'key': 'http_password',
                        'value': pws_data.http_password
                    },
                    {
                        'key': 'upload_method',
                        'value': AUTOMATED_HTTP
                    }
                ];

            }
            else if (pws_data.upload_method === AUTOMATED_FTP) {

                pws_data.configurations = [
                    {
                        'key': 'ftp_username',
                        'value': pws_data.ftp_username
                    },
                    {
                        'key': 'ftp_password',
                        'value': pws_data.ftp_password
                    },
                    {
                        'key': 'remote_directory',
                        'value': pws_data.remote_directory
                    },
                    {
                        'key': 'remote_folder',
                        'value': pws_data.remote_folder
                    },
                    {
                        'key': 'ftp_host',
                        'value': pws_data.ftp_host
                    },
                    {
                        'key': 'upload_method',
                        'value': AUTOMATED_FTP
                    }

                ];
            }
            else if (pws_data.upload_method === MANUAL) {
                delete pws_data.frequency;
            }
            delete pws_data.upload_method;
            delete pws_data.ftp_username;
            delete pws_data.ftp_password;
            delete pws_data.remote_directory;
            delete pws_data.remote_folder;
            delete pws_data.ftp_host;
            delete pws_data.http_password;
            delete pws_data.http_username;
        }

        function arrangePWSDataToPopulateInForm(pws_data) {

            var arranged_data = {

                'longitude': pws_data.location.longitude,
                'latitude': pws_data.location.latitude,
                'make_model': pws_data.make_model,
                'provider_reference': pws_data.provider_reference,
                'slug': pws_data.slug,
                'gateway_id': pws_data.gateway_id,
                'frequency': pws_data.frequency,
                'is_public': pws_data.is_public,
                'location_desc': pws_data.location_desc
            };

            for (i = 0; i < pws_data.configurations.length; i++) {

                arranged_data[pws_data.configurations[i].key] = pws_data.configurations[i].value;
            }
            arranged_data['parameters'] = [];

            for (i = 0; i < pws_data.nodes.length; i++) {
                if (pws_data.nodes[i].is_default === true) {
                    arranged_data['default_node_slug'] = pws_data.nodes[i].slug;
                    for (j = 0; j < pws_data.nodes[i].sensors.length; j++) {
                        arranged_data['parameters'].push(pws_data.nodes[i].sensors[j].parameter)
                    }
                    pws_data.nodes.splice(i, 1);
                    break;
                }
            }
            arranged_data['nodes'] = pws_data.nodes;
            return arranged_data;
        }

    });

