angular.module("WeatherApp").controller("WeatherActualController",

    function ($scope, $rootScope, NgTableParams, $mdDialog, UserSettingsFactory, FieldNamesFactory,
              $khMessage, FarmFiltersService, WeatherService, WeatherUtilsService) {


        defineScopeFunctions();
        defineScopeVariables();
        getUserSettings();

        $rootScope.currentWeatherCardViewTab = 'weatherActual';
        var debounce_loadNewData = _.debounce(loadNewData, 400);

        if ($rootScope.selected_farm || $rootScope.currentWeatherCardViewTab === 'shortTermForecast') {
            $rootScope.currentWeatherCardViewTab = 'weatherActual';
            FarmFiltersService.setDefaultFieldFarm();
            debounce_loadNewData();
        }

        function loadNewData() {
            emptyCurrentActualSummaryData();
            emptyCurrentChartTableData(function () {
                loadActualContent();

            });
        }

        $scope.$on("filter:selections_updated",
            function () {
                if ($rootScope.currentWeatherCardViewTab === 'weatherActual') {
                    FarmFiltersService.setDefaultFieldFarm();
                    debounce_loadNewData();
                }
            }
        );

        function defineScopeFunctions() {
            $scope.setCurrentItem = setCurrentItem;
            $scope.changeDataView = changeDataView;
            $scope.loadActualContent = loadActualContent;
            $scope.changeParamOnClick = changeParamOnClick;
            $scope.exportWeatherData = exportWeatherData;
            $scope.changeDataOnClick = changeDataOnClick;
            $scope.emptySelectedParams = emptySelectedParams;
        }

        function defineScopeVariables() {
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.currentItem = 0;
            $scope.dataFrequency = "hourly";
            $scope.availableParams = [];
            $scope.selected_parameters = ["precipitation", "temperature", "wind_speed", "wind_direction"];
            $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
            $scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();
            $scope.currentDataView = 'chartView';
            $scope.currentChartTableData = null;
            $scope.enableWeatherDataExportButton = true;
            $scope.parametersData = {
                selected_parameters: []
            };
        }

        function getUserSettings() {
            UserSettingsFactory.get(function (result) {
                $scope.user_settings = result;
            });
        }

        function changeParamOnClick(selectedParams) {
            $scope.selected_parameters = selectedParams;
            var indexOfWindSpeed = $scope.selected_parameters.indexOf('wind_speed');
            if (indexOfWindSpeed !== -1) {
                if ($scope.selected_parameters.indexOf('wind_direction') === -1) {
                    $scope.selected_parameters.splice(indexOfWindSpeed + 1, 0, 'wind_direction')
                }
            } else {
                WeatherUtilsService.removeElementFromArray($scope.selected_parameters, 'wind_direction');
            }

            var indexOfMeanWindSpeed = $scope.selected_parameters.indexOf('mean_wind_speed');
            if (indexOfMeanWindSpeed !== -1) {
                if ($scope.selected_parameters.indexOf('mode_wind_direction') === -1) {
                    $scope.selected_parameters.splice(indexOfWindSpeed + 1, 0, 'mode_wind_direction')
                }
            } else {
                WeatherUtilsService.removeElementFromArray($scope.selected_parameters, 'mode_wind_direction');
            }
            buildDataView();
        }

        function loadActualContent() {
            getActualSummary();
            $scope.currentItem = 0;
            $scope.changeDataOnClick('Last 24 Hour');

        }

        function setCurrentItem(itemId) {
            if (itemId === $scope.currentItem) {
                $scope.currentItem = null;
            } else {
                $scope.currentItem = itemId;
            }
        }

        function changeDataView(selectedDataView) {
            $scope.currentDataView = selectedDataView;
        }

        function getDataQueryDict() {
            var queryParamDict = {};
            queryParamDict['account_key'] = "kisanhub-account";
            queryParamDict['localize'] = "1";
            queryParamDict['only_observed'] = "1";
            return queryParamDict;
        }

        function changeDataOnClick(actualSummaryKey) {
            var dataQueryDict = getDataQueryDict();
            emptyCurrentChartTableData();

            if (actualSummaryKey === 'Last 24 Hour') {
                $scope.selected_parameters = ["precipitation", "temperature", "wind_speed", "relative_humidity"];
                dataQueryDict['parameters'] = "precipitation,temperature,wind_speed,wind_direction,relative_humidity,pressure";
                dataQueryDict['start_time'] = WeatherUtilsService.getDbDateHourlyFormatHour(WeatherUtilsService.subtractHours(moment.utc(), 23));
                dataQueryDict['end_time'] = WeatherUtilsService.getDbDateHourlyFormatCurrentHourUTC();
                dataQueryDict["frequency"] = 'hourly';
                dataQueryDict["input_times_in_utc"] = "1";
                getActualHourlyData(dataQueryDict, 'Last 24 Hour');
            }
            if (actualSummaryKey === 'Last Week') {
                dataQueryDict['parameters'] = "total_precipitation,max_temperature,mean_temperature,min_temperature,mean_wind_speed,mean_relative_humidity,mean_pressure,mode_wind_direction,ref_et_penman";
                dataQueryDict['params_as_daily'] = '1';
                dataQueryDict['frequency'] = 'daily';
                dataQueryDict['start_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 7));
                dataQueryDict['end_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 1));
                getActualDailyData(dataQueryDict);
            }
            if (actualSummaryKey === 'Last Month') {
                dataQueryDict['parameters'] = "total_precipitation,max_temperature,mean_temperature,min_temperature,mean_wind_speed,mean_relative_humidity,mean_pressure,mode_wind_direction,ref_et_penman";
                dataQueryDict['params_as_daily'] = '1';
                dataQueryDict['frequency'] = 'daily';
                dataQueryDict['start_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 30));
                dataQueryDict['end_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 1));
                getActualDailyData(dataQueryDict);
            }
            if (actualSummaryKey === 'Last 6 Months') {
                dataQueryDict['parameters'] = "total_precipitation,max_temperature,mean_temperature,min_temperature,mean_wind_speed,mean_relative_humidity,mean_pressure,mode_wind_direction,ref_et_penman";
                dataQueryDict['params_as_daily'] = '1';
                dataQueryDict['frequency'] = 'daily';
                dataQueryDict['start_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 180));
                dataQueryDict['end_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 1));
                getActualDailyData(dataQueryDict);
            }

            if (actualSummaryKey === 'Last Year') {
                dataQueryDict['parameters'] = "total_precipitation,max_temperature,mean_temperature,min_temperature,mean_wind_speed,mean_relative_humidity,mean_pressure,mode_wind_direction,ref_et_penman";
                dataQueryDict['params_as_daily'] = '1';
                dataQueryDict['frequency'] = 'daily';
                dataQueryDict['start_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 365));
                dataQueryDict['end_date'] = WeatherUtilsService.getDbDateDailyFormatDay(WeatherUtilsService.subtractDays(moment(), 1));
                getActualDailyData(dataQueryDict);
            }
        }


        function getActualDailyData(queryDict) {
            emptyCurrentChartTableData();
            $scope.chartConfiguration.lang.noData = WeatherUtilsService.MessageWeatherChartDataLoading;
            WeatherService.getWeatherData('blended-data',WeatherService.getSlugType(),queryDict).then(function (apiData) {
                    populateFarmFieldInformation();

                    if (apiData.data && apiData.data.length > 0) {
                        $scope.currentChartTableData = apiData.data;
                        $scope.availableParams = getAvailableParams(apiData);

                        // Update selection only if previous tab was from hourly data set
                        if ($scope.dataFrequency === 'hourly') {
                            $scope.selected_parameters = ["total_precipitation", "mean_temperature", "mean_wind_speed", "mean_relative_humidity"];
                            $scope.parametersData.selected_parameters = ["total_precipitation", "mean_temperature", "mean_wind_speed", "mean_relative_humidity"];
                        }
                        $scope.dataFrequency = "daily";
                        updateDataFormat('daily');
                        buildDataView();
                        $scope.weather_status = "";
                        getDataSourceDetails(apiData);
                    }
                    else {
                        emptySelectedParams();
                        emptyCurrentChartTableData();
                        $scope.chartConfiguration = null;
                    }
                },
                function () {
                    populateFarmFieldInformation();
                    $khMessage.show("Server time out. Error while fetching data!", 4000);
                    emptySelectedParams();
                    emptyCurrentChartTableData();
                    $scope.chartConfiguration = null;
                });
        }

        function getActualHourlyData(queryDict, actualSummaryKey) {
            $scope.chartConfiguration.lang.noData = WeatherUtilsService.MessageWeatherChartDataLoading;
            WeatherService.getWeatherData('blended-instantaneous', WeatherService.getSlugType(), queryDict).then(function (apiData) {
                    populateFarmFieldInformation();
                    if (actualSummaryKey === 'Last 24 Hour') {
                        if (apiData && apiData.data.length > 0) {
                            $scope.dataFrequency = "hourly";
                            $scope.currentChartTableData = apiData.data;
                            $scope.availableParams = getAvailableParams(apiData);
                            updateDataFormat('hourly');
                            buildDataView();
                            $scope.weather_status = "";
                            getDataSourceDetails(apiData);
                            $scope.parametersData.selected_parameters = ["precipitation", "temperature", "wind_speed", "wind_direction"];
                        } else {
                            emptySelectedParams();
                            emptyCurrentChartTableData();
                            $scope.chartConfiguration = null;
                        }

                    } else {
                        if (apiData && apiData.data.length > 0) {
                            $scope.dataFrequency = "hourly";
                            $scope.currentChartTableData = apiData.data;
                            $scope.availableParams = getAvailableParams(apiData);
                            updateDataFormat('hourly');
                            buildDataView();
                            $scope.weather_status = "";
                            getDataSourceDetails(apiData);
                        } else {
                            emptySelectedParams();
                            emptyCurrentChartTableData();
                            $scope.chartConfiguration = null;
                        }
                    }
                },
                function () {
                    populateFarmFieldInformation();
                    emptySelectedParams();
                    emptyCurrentChartTableData();
                    $scope.chartConfiguration = null;

                }
            );
        }

        function getAvailableParams(apiData) {
            return WeatherUtilsService.getParametersNamevalueDictList(apiData.metadata.parameters);
        }

        function getDataSourceDetails(apiData) {
            $scope.source_attribution = apiData.metadata.source.join(", ");
        }

        function updateDataFormat(dataType) {
            if (dataType === 'daily') {
                $scope.xAxisFormat = {
                    type: 'datetime',
                    labels: {
                        format: '{value: %e %b, %Y}'
                    }
                };
                $scope.tableDataDateType = 'daily';
            }
            else {
                $scope.xAxisFormat = {
                    type: 'datetime',
                    labels: {
                        format: '{value: %H:%M}'
                    }
                };
                $scope.tableDataDateType = 'hourly';
            }
        }

        function getActualSummary() {
            $rootScope.currentWeatherTabView = 'WeatherActual';
            WeatherService.getWeatherData('blended-duration-summaries', WeatherService.getSlugType(), getDataQueryDict()).then(function (apiData) {
                if (apiData) {
                    $scope.actualSummaryData = apiData.data.data;
                    $scope.actualSummaryKeys = apiData.data.summary_keys;
                }
            });
        }

        function buildDataView() {
            $scope.enableWeatherDataExportButton = true;
            buildChart();
            buildTable();
        }

        function removeNonRequiredParamsFromChartParams(chartParams) {
            chartParams = WeatherUtilsService.removeElementFromArray(chartParams, 'wind_direction');
            chartParams = WeatherUtilsService.removeElementFromArray(chartParams, 'mode_wind_direction');
            return chartParams;
        }


        function buildChart() {
            var chartParams = angular.copy($scope.selected_parameters);
            if (chartParams.length === 0) {
                chartParams = ["precipitation", "temperature", "wind_speed", "wind_direction"];
            }
            chartParams = removeNonRequiredParamsFromChartParams(chartParams);
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            WeatherUtilsService.getChartConfig($scope, chartParams, $scope.xAxisFormat);
            angular.forEach(chartParams, function (chartParam, index) {
                    if (chartParam === 'precipitation') {
                        $scope.chartConfiguration.series[index].type = 'column';
                    }
                }
            );
            var extraChartSettings = {
                'conversionRequired': true,
                'conversionMethod': parseInt,
                'xyValuePush': true,
                'includeWindDirection': true
            };
            WeatherUtilsService.pushListDataInChart($scope, $scope.currentChartTableData, chartParams, extraChartSettings);
        }

        function buildTable(type) {
            $scope.tableData = $scope.currentChartTableData;
        }

        function emptyCurrentChartTableData(callback) {
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.availableParams = [];
            $scope.currentChartTableData = null;
            $scope.tableData = [];
            $scope.enableWeatherDataExportButton = false;
            $scope.dataFromWeatherStation = false;
            $scope.source_attribution = null;
            if (callback) {
                callback();
            }
        }

        function emptyCurrentActualSummaryData() {
            $scope.actualSummaryData = null;
            $scope.actualSummaryKeys = [];
        }

        function emptySelectedParams() {
            $scope.selected_parameters = [];
            $scope.parametersData.selected_parameters = [];
        }

        function populateFarmFieldInformation() {
            if (typeof($rootScope.selected_field) !== 'undefined' && $rootScope.selected_field.name !== null && $rootScope.selected_field.name !== "All Fields") {
                $scope.weather_location_information = "Local weather for " + $rootScope.selected_field.name + ', ' + $rootScope.selected_farm.name;
                $scope.weather_status = " will be available soon.";
            }
            else if (typeof($rootScope.selected_farm.name) !== 'undefined' && $rootScope.selected_farm.name !== null) {
                $scope.weather_location_information = "Local weather for " + $rootScope.selected_farm.name;
                $scope.weather_status = " will be available soon.";
            } else {
                $scope.weather_location_information = "No Farm / Field created.";
                $scope.chartConfiguration = null;
                $khMessage.show("No farm found", 4000);
            }
        }

        function exportWeatherData(ev) {
            $mdDialog.show({
                controller: "WeatherActualDataExportController",
                templateUrl: '/static/assets/khapps/weather/partials/abstract/WeatherDataExport.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                locals: {
                    paramsData: {
                        selectedParams: $scope.selected_parameters,
                        availableParams: $scope.availableParams,
                        dataFrequency: $scope.dataFrequency,
                        location_slug: getDataQueryDict().location_slug
                    }
                },
                clickOutsideToClose: false
            });
        }
    }
);