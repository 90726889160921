angular.module("WeatherApp").factory("WeatherOverrideRecordFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/weather/override/', {}, {

        query: {
            params: {},
            url: '/api/v1.0/weather/override/',
            isArray: true
        },
        save: {
            method: 'POST'
        },
        remove: {
            method: 'DELETE'
        }

    });
}]);