
angular.module("WeatherApp").factory("ClimateAnnualDataFactory", ["$resource", function ($resource) {

    var url = '/api/v1.0/weather/regionalclimatesummary/';
    var queryParamDict = {
        region: '@region'
    }

    return $resource(url, {},
        {
            get: {
                params: queryParamDict,
                url: url
            }
        }
    );
}]);

