angular.module("WeatherApp").service("FMWeatherDataChartConfigFactory", [function () {


    this.getChartConfig = function () {


        return {
            options: {
                chart: {
                    zoomType: 'xy'
                },
                title: {
                    text: ''
                },
                xAxis: [{
                    type: 'datetime'
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        formatter: function () {
                            return this.value + ' mm';
                        },
                        style: {
                            color: '#4572A7'
                        }
                    },
                    title: {
                        text: 'Rainfall',
                        style: {
                            color: '#4572A7'
                        }
                    },
                    opposite: true

                }, { // Secondary yAxis
                    gridLineWidth: 0,
                    title: {
                        text: 'Tempearture',
                        style: {
                            color: '#89A54E'
                        }
                    },
                    labels: {
                        formatter: function () {
                            return this.value + '°C';
                        },
                        style: {
                            color: '#89A54E'
                        }
                    }

                }, { // Tertiary yAxis
                    gridLineWidth: 0,
                    title: {
                        text: 'Relative Humidity',
                        style: {
                            color: '#CC834A'
                        }
                    },
                    labels: {
                        formatter: function () {
                            return this.value + ' %';
                        },
                        style: {
                            color: '#CC834A'
                        }
                    },
                    opposite: true,
                    max: 100
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'top',
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColorSolid) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false,
                    y: -4
                }
            },
            series: [{
                name: 'Rainfall',
                color: '#4572A7',
                type: 'column',
                yAxis: 0,
                data: [],
                tooltip: {
                    valueSuffix: ' mm',
                    valueDecimals: 2
                }

            }, {
                name: 'Temperature',
                type: 'spline',
                color: '#89A54E',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }

            }, {
                name: 'Relative Humidity',
                color: '#CC834A',
                type: 'spline',
                yAxis: 2,
                data: [],
                tooltip: {
                    valueSuffix: ' %',
                    valueDecimals: 2
                }
            }]
        };


    }


}]);