
angular.module("WeatherApp").factory("ClimateMonthlyDataFactory", ["$resource", function ($resource) {

    var url = '/api/v1.0/weather/regionalclimatesummary/';
    var queryParamDict = {
        region: '@region',
        year:'@year'
    };

    return $resource(url, {},
        {
            get: {
                params: queryParamDict,
                url: url
            }
        }
    );
}]);

