angular.module("WeatherApp").controller('PDSDetailChartTableController',
    function ($scope, $rootScope, EAReadingsResource, $mdDialog, $khMessage, $khPulse, PlatformSettingsFactory, WeatherUtilsService) {
        defineScopeFunctions();

        defineScopeVariables();

        loadInitialData();

        function loadInitialData() {
            emptyCurrentData();
            setDefaultFetchDates();
            getStationData();
        }


        function setDefaultFetchDates() {
            if (!$scope.dataFrequency) {
                $scope.dataFrequency = "hourly";
            }
            $scope.maxStartDate = moment(new Date()).toDate();

            $scope.minStartDate = moment("2017-04-25").toDate();

            var timeDurationParams = getTimeDurationParams();
            $scope.updateFetchDates(moment(new Date()).subtract(timeDurationParams[1], timeDurationParams[0]).toDate())
        }

        function defineScopeFunctions() {
            $scope.setCurrentItem = function (itemId) {
                if (itemId == $scope.currentItem) {
                    $scope.currentItem = null;
                } else {
                    $scope.currentItem = itemId;
                }
            };

            $scope.updateFrequency = function (dataFrequency) {
                /*
                 Dates needs updates as they have different duration( hourly has 7 days , daily has 6 months)
                 Available drop down needs update because daily and hourly params are different
                 */
                $scope.dataFrequency = dataFrequency;
                var today = moment();
                var timeDurationParams = getTimeDurationParams();
                var endDate = moment(angular.copy($scope.dataFetchStartDate)).add(timeDurationParams[1], timeDurationParams[0]);
                if (endDate.isAfter(today)) {
                    $scope.dataFetchEndDate = today.toDate();
                } else {
                    $scope.dataFetchEndDate = endDate.toDate();
                }
                availableParametersDropDown();
            };

            $scope.updateFetchDates = function (startDate) {
                /*
                 Updates start date and end dates
                 End date is addition of 15 days to chosen start date
                 */
                var timeDurationParams = getTimeDurationParams();
                var endDate = moment(startDate).add(timeDurationParams[1], timeDurationParams[0]);
                var today = moment();
                if (endDate.isAfter(today)) {
                    $scope.dataFetchEndDate = today.toDate();
                } else {
                    $scope.dataFetchEndDate = endDate.toDate();
                }
                $scope.dataFetchStartDate = startDate;
            };

            $scope.changeDataView = function (selectedDataView) {
                $scope.currentDataView = selectedDataView;
            };

            $scope.changeParamOnClick = function (selectedParams) {
                /*
                 Removes wind direction from selected params as only Wind terminology is being used for both wind speed and direction
                 */
                $scope.selected_parameters = selectedParams;
                var indexOfWindSpeed = $scope.selected_parameters.indexOf('wind_speed');
                if (indexOfWindSpeed != -1) {
                    if ($scope.selected_parameters.indexOf('wind_direction') == -1) {
                        $scope.selected_parameters.splice(indexOfWindSpeed + 1, 0, 'wind_direction')
                    }
                } else {
                    WeatherUtilsService.removeElementFromArray($scope.selected_parameters, 'wind_direction');
                }
                emptyCurrentData();
                getStationData();
            };

        }

        function getTimeDurationParams() {
            /*
             Sets diff between start end end date based on data frequency
             */
            if ($scope.dataFrequency == "daily") {
                return ["months", 6]
            } else {
                return ["days", 7]
            }

        }

        function defineScopeVariables() {
            $scope.showDateFilters = true;
            $scope.source_attribution = "This uses Environment Agency rainfall data from the " +
                "real-time data API (Beta)";
            $scope.showDataFrequencyFilters = true;
            $scope.availableFrequencies = [
                {"name": "Intraday", "value": "hourly"}
            ];
            $scope.dataFrequency = "hourly";
            availableParametersDropDown();
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.chartConfiguration.series = [];
            $scope.currentItem = 0;
            $scope.selected_parameters = ["precipitation"];
            $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
            $scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();
            $scope.currentDataView = 'chartView';
            $scope.currentChartTableData = null;
            $scope.enableWeatherDataExportButton = false;
            $scope.userSettingDateTimeFormat = $rootScope.userSettingDateTimeFormat;
        }

        function getStationData() {
            $khPulse.show();
            $scope.chartConfiguration.lang.noData = WeatherUtilsService.MessageWeatherChartDataLoading;

            EAReadingsResource.get(getApiQueryParamDict(), function (apiData) {

                if (apiData.data) {
                    if (Object.keys(apiData.data).length > 0) {
                        $scope.currentChartTableData = apiData.data;
                        updateDataFormat(angular.copy($scope.dataFrequency));
                        buildDataView();
                    } else {
                        emptyCurrentData();
                        $scope.chartConfiguration = false;
                    }
                }
                else {
                    emptyCurrentData();
                    $scope.chartConfiguration = false;
                }
                $khPulse.hide();
            }, function (error) {
                emptyCurrentData();
                $scope.chartConfiguration = false;
                $khPulse.hide();
            })
        }

        function getApiQueryParamDict() {

            var queryParamDict = {
                "data_format": "chart-table",
                "slug": $rootScope.pds.properties.slug
            };
            return angular.extend(queryParamDict, getFetchingDateDict())
        }

        function getFetchingDateDict() {
            return {
                start_date: WeatherUtilsService.getDbDate($scope.dataFetchStartDate),
                end_date: WeatherUtilsService.getDbDate($scope.dataFetchEndDate)
            }
        }


        function availableParametersDropDown() {
            $scope.availableParams = WeatherUtilsService.getParametersNamevalueDictList(["precipitation"]);
            $scope.selected_parameters = ["precipitation"];
        }

        function updateDataFormat(dataType) {
            $scope.tableDataDateType = dataType;

            if (dataType == 'daily') {
                $scope.xAxisFormat = {
                    type: 'datetime',
                    labels: {
                        format: '{value: %e %b, %Y}'
                    }
                };
            }
            else {
                $scope.xAxisFormat = {
                    type: 'datetime'
                };
            }
        }

        function buildDataView() {
            buildChart();
            buildTable();
        }

        function removeNonRequiredParamsFromChartParams(chartParams) {
            chartParams = WeatherUtilsService.removeElementFromArray(chartParams, 'wind_direction');
            return chartParams
        }

        function buildChart() {
            var chartParams = angular.copy($scope.selected_parameters);
            chartParams = removeNonRequiredParamsFromChartParams(chartParams);
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.chartConfiguration.series = [];
            WeatherUtilsService.getChartConfig($scope, chartParams, $scope.xAxisFormat);

            angular.forEach(chartParams, function (chartParam, index) {
                    if (chartParam == 'precipitation' && $scope.dataFrequency == "hourly") {
                        $scope.chartConfiguration.series[index].type = 'bar'
                    }
                }
            );

            var extraChartSettings = {
                'conversionRequired': true,
                'conversionMethod': parseInt,
                'xyValuePush': true,
                'includeWindDirection': false
            };
            WeatherUtilsService.pushListDataInChart($scope, $scope.currentChartTableData, chartParams, extraChartSettings);
        }

        function buildTable() {
            $scope.tableData = $scope.currentChartTableData;
        }

        function emptyCurrentData() {
            $scope.chartConfiguration = WeatherUtilsService.getBaseChartConfig();
            $scope.chartConfiguration.series = [];
            $scope.currentChartTableData = null;
            $scope.tableData = [];
        }


    });
