angular.module('user_notification_preferences').factory("UserNotificationSubscriptionFactory", ["$resource",

    function ($resource) {

    return $resource('/api/v2.0/notification/user_notification_preferences/subscribed_notifications', {}, {

        save: {
            url: '/api/v2.0/notification/user_notification_preferences/subscribed_notifications/',
            method: 'POST'
        },
        get: {
            method: 'GET'
        }
    });
}]);