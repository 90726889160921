import { downgradeComponent } from '@angular/upgrade/static';
import { WeatherForecastCardComponent } from '../../angular/src/app/weather/weather-forecast-card/weather-forecast-card.component';
import { WeatherForecastComponent } from '../../angular/src/app/weather/weather-forecast/weather-forecast.component';

angular.module('WeatherComponents', []);

export default angular.module('WeatherApp', [
  'KhBase',
  'FiltersData',
  'highcharts-ng',
  'leaflet-directive',
  'user_notification_preferences',
  'KisanSatApp',
  'khUtils',
  'ApiUtils.ksat',
  'KisanAuthApp',
  'ShareWithApp',
  'ngMessages',
  'WeatherComponents'
]);

angular
  .module('WeatherComponents')
  .directive(
    'weatherForecastCard',
    downgradeComponent({
      component: WeatherForecastCardComponent,
      inputs: ['name', 'slug', 'type']
    })
  )
  .directive(
    'weatherForecast',
    downgradeComponent({
      component: WeatherForecastComponent,
      inputs: ['slug', 'name', 'type', 'days']
    })
  );
