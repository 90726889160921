
(function (angular) {
    "use strict";
 angular
        .module('WeatherApp')
        .config(function ($mdDateLocaleProvider, $translateProvider) {
            $translateProvider.translations('en', {
                farm: "Farm",
                field: "Field",
                weather_override_title: "Weather Override",
                weather_override_table_headers:'Overrides for',
                actions: 'Actions',
                edit: 'Edit',
                delete: 'Delete',
                date: 'Date',
                parameters: 'Parameters',
                value: 'Value',
                save: 'Save',
                add: 'Add',
                station_name:'Station Name',
                make_model:'Make Model',
                status:'Status',
                assigned_fields:'Assigned fields',
                assign_fields:'Assign fields',
                cancel:'Cancel',
                assign:'Assign',
                selected:'Selected',
                remove:'Remove',
                all:'All',
                select:'Select',
                station:'Station',
                name:'Name',
                sensor:'Sensor',
                sensors:'Sensors',
                weather:'Weather',
                stations:'Stations',
                configuration:'Configuration',
                channel:'Channel',
                id:'ID',
                parameter:'Parameter',
                sensor_configuration:'Sensor Configuration (Channel ID - Make Model - Parameter)',
                public:'Public',
                gateway_type:'Station Type',
                sensor_group:'Sensor Group',
                personal:'Personal',
                actual:'Actual',
                forecast:'Forecast',
                region:'Region',
                lat_lon:'Latitude, Longitude',
                geolocation:'Geolocation',
                provider:'Provider',
                provider_reference:'Provider Reference',
                type:'Type',
                location:'Location',
                location_desc: 'Location Description',
                start_date:'Start Date',
                time:'Time',
                date_time:'Date Time',
                excel:'EXCEL',
                export_as:'Export as',
                export_as_excel:'Export as EXCEL',
                table_view:'Table view',
                chart_view:'Chart view',
                end_date:'End date',
                frequency:'Frequency',
                source:'Source',
                max_temp:'Maximum temperature',
                min_temp:'Minimum temperature',
                rainfall:'Rainfall',
                wind_speed:'Wind Speed',
                wind_direction:'Wind Direction',
                ref_et_penman:'Ref ET',
                avg:'Average',
                temperature:'Temperature',
                total:'Total',
                distance_in_km:'Distance (Km)',
                distance:'Distance'
            });
        });
})(angular);