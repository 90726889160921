(function (angular) {
    'use strict';
    angular.module('WeatherApp')
        .controller('AddPWSSensorGroupController', AddPWSSensorGroupController);

    AddPWSSensorGroupController.$inject = ['$scope', '$rootScope', '$filter', '$mdDialog', '$khMessage', 'NgTableParams',
        'sensorGroup', 'existingSourceNodeId', 'reservedAPIParseKeys', 'WeatherUtilsService'];

    function AddPWSSensorGroupController($scope, $rootScope, $filter, $mdDialog, $khMessage, NgTableParams, sensorGroup,
                                         existingSourceNodeId, reservedAPIParseKeys, WeatherUtilsService) {
        var vm = this;
        vm.existingSourceNodeId = existingSourceNodeId.join(',');
        vm.reservedAPIParseKeys = reservedAPIParseKeys.join(',');

        var PWSSensorGroupFormlyFields = function () {
            return [
                {
                    className: "flex-100",
                    type: "input",
                    key: "node_id",
                    id: "node_id",
                    templateOptions: {
                        label: "Name",
                        required: true
                    }
                }
            ]
        };


        function setUpForm() {
            vm.getAvailableParameters();
            vm.sensorGroupModel = sensorGroup;
            if (!vm.sensorGroupModel.sensors) {
                vm.sensorGroupModel.sensors = [];
                vm.sensorGroupModel.sensors.push({});
                vm.updateFlag = false;
            }
            else {
                vm.updateFlag = true;
            }
            vm.getParameterList();
            vm.PWSSensorGroupFormlyFields = PWSSensorGroupFormlyFields();
        }

        vm.getUnitList = function (sensor) {
            var measureOf = $rootScope.parameterMeasureOf[sensor.parameter];
            sensor.unit_list = angular.copy($rootScope.unitsGroupbyMeasureOf[measureOf]);
            return true;
        };

        vm.getAvailableParameters = function () {
            vm.availableParameters = WeatherUtilsService.getParametersNamevalueDictList($rootScope.pwsParametersOptions);
        };

        vm.cancelAddSensorGroup = function () {
            $mdDialog.cancel();
        };

        vm.saveSensorModel = function () {
            $mdDialog.hide(vm.sensorGroupModel)
        };

        vm.addPWSParameter = function () {

            vm.sensorGroupModel.sensors.push({});
            vm.getParameterList();
        };

        vm.getParameterList = function () {
            var paramList = [];
            if (vm.sensorGroupModel.sensors) {
                for (var i = 0; i < vm.sensorGroupModel.sensors.length; i++) {
                    vm.sensorGroupModel.sensors[i].parameter_list = angular.copy(vm.availableParameters);
                    vm.getUnitList(vm.sensorGroupModel.sensors[i]);
                    paramList.push(vm.sensorGroupModel.sensors[i].parameter);
                }

                for (var i = 0; i < vm.sensorGroupModel.sensors.length; i++) {
                    var notFound = true;
                    for (var k = 0; k < vm.sensorGroupModel.sensors[i].parameter_list.length; k++) {
                        if (paramList.indexOf(vm.sensorGroupModel.sensors[i].parameter_list[k].value) !== -1 &&
                            vm.sensorGroupModel.sensors[i].parameter_list[k].value !== vm.sensorGroupModel.sensors[i].parameter
                        ) {
                            vm.sensorGroupModel.sensors[i].parameter_list.splice(k, 1);
                        }

                        if (vm.sensorGroupModel.sensors[i].parameter_list[k] &&
                            vm.sensorGroupModel.sensors[i].parameter_list[k].value === vm.sensorGroupModel.sensors[i].parameter) {
                            notFound = false;
                        }
                    }
                    if (notFound) {
                        delete vm.sensorGroupModel.sensors[i].parameter;
                        delete vm.sensorGroupModel.sensors[i].unit;
                    }
                }
            }
        };

        vm.removeParam = function (index) {
            vm.sensorGroupModel.sensors.splice(index, 1)

        };
        setUpForm();
    }

})(angular);

