angular
  .module('WeatherApp')
  .controller('WeatherLandingController', function(
    $scope,
    $rootScope,
    WeatherService,
    FarmFiltersService
  ) {
    //Weather landing card view code

    $rootScope.currentWeatherCardViewTab = 'shortTermForecast';
    setUpWeatherLandingCardViewSecondaryNavBarParameters();
    $scope.showFarmBar = true;

    $rootScope.changeWeatherCardViewTabs = function(selectedTabView) {
      $rootScope.currentWeatherCardViewTab = selectedTabView;
    };

    $scope.changeDataView = function(selectedDataView) {
      $scope.currentDataView = selectedDataView;
    };

    // These 2 properties trigger the first call
    $scope.slug = null;
    $scope.type = null;
    loadNewData();
    function loadNewData() {
      $scope.slug = FarmFiltersService.filter.field_slug !== "All Fields" ? FarmFiltersService.filter.field_slug : null;
      $scope.type = 'field';
      let field_name = FarmFiltersService.getSelectedField() !== undefined ? FarmFiltersService.getSelectedField().name : null;
      let farm_name = FarmFiltersService.getSelectedFarm() !== undefined ? FarmFiltersService.getSelectedFarm().name : null;
      $scope.name = field_name + ', ' + farm_name;
    }

    /**
     * I am bringing this from the old and over/unused ShortTermForecastController
     */
    $scope.$on('filter:selections_updated', function() {
      if ($rootScope.currentWeatherCardViewTab === 'shortTermForecast') {
        $scope.currentItem = 0;
        FarmFiltersService.setDefaultFieldFarm();
        loadNewData();
      }
    });

    function setUpWeatherLandingCardViewSecondaryNavBarParameters() {
      $rootScope.pageTitle = 'AgroMet';
      $rootScope.page_id = 'weather';
      $rootScope.sub_app_page_id = null;
      $rootScope.show_region = false;
      $rootScope.show_options = false;
      $rootScope.showFarmDropDown = true;
      $rootScope.showFieldDropDown = true;
      $rootScope.showPWSDropDown = false;
      $rootScope.showDataLoggerSubLinks = false;
      $rootScope.show_override = false;
      $rootScope.showWeatherLandingCardViewSubLinks = true;
      $rootScope.showSensorListMainCardViewSubLinks = false;
    }
  });
