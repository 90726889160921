angular.module('WeatherApp').service("WeatherFunctionHelperService", function () {

    this.setUpSensorListSecondaryNavBarParameters = function ($rootScope) {
        $rootScope.pageTitle = "AgroMet";
        $rootScope.page_id = "weather";
        $rootScope.sub_app_page_id = "sensor_list";
        $rootScope.show_options = true;
        $rootScope.show_region = false;
        $rootScope.showFarmDropDown = true;
        $rootScope.showFieldDropDown = true;
        $rootScope.showPWSDropDown = true;

        $rootScope.showDataLoggerSubLinks = true;
        $rootScope.show_override = false;
        $rootScope.showWeatherLandingCardViewSubLinks = false;
        $rootScope.showSensorListMainCardViewSubLinks = true;

    };


    this.loadInitialData = function ($scope, $rootScope, FieldNamesFactory, $khMessage,
                                     loadDataFunction) {

        if ($rootScope.selected_farm) {

            if ($rootScope.selected_farm.value == "All Farms" || $rootScope.selected_farm == "All Farms") {
                setFarmFromFarmList($scope, $rootScope, $khMessage, FieldNamesFactory, loadDataFunction);
            }
            else if ($rootScope.selected_field.value == "All Fields" || $rootScope.selected_field == "All Fields") {
                setFieldFromList($scope, $rootScope, $khMessage, FieldNamesFactory, loadDataFunction);
            }
            else {
                loadDataFunction();
            }
        }
        else {
            setFarmFromFarmList($scope, $rootScope, $khMessage, FieldNamesFactory, loadDataFunction);
        }
    };

    function setFieldFromList($scope, $rootScope, $khMessage, FieldNamesFactory, loadDataFunction) {
        FieldNamesFactory.query({farm: $rootScope.selected_farm.value}, function (result) {

            $rootScope.fields_list = result;
            if (result.length > 0) {
                result.unshift({name: "All Fields", value: "All Fields"});
                $rootScope.selected_field = result[1];
                $rootScope.initial_field_value = $rootScope.selected_field.value;
                loadDataFunction();

            }
            else {
                $rootScope.selected_field = {name: null, value: null};
                if ($rootScope.sub_app_page_id == "weather_override") {
                    $khMessage.show("No field found for current farm selection", 4000);
                    $scope.chartConfiguration = false;
                } else {
                    loadDataFunction();
                }
            }
            $rootScope.update_growing_season_farm_field_session({
                field: $rootScope.selected_field.value,
                farm: $rootScope.selected_farm.value
            });

        });
    }

    function setFarmFromFarmList($scope, $rootScope, $khMessage, FieldNamesFactory, loadDataFunction) {

        if ($rootScope.farm_list.length > 1) {
            $rootScope.selected_farm = $rootScope.farm_list[1];
            $rootScope.initial_farm_value = $rootScope.selected_farm.value;
            FieldNamesFactory.query({farm: $rootScope.selected_farm.value}, function (result) {
                $rootScope.fields_list = result;
                if (result.length > 0) {
                    result.unshift({name: "All Fields", value: "All Fields"});
                    $rootScope.selected_field = result[1];
                    $rootScope.initial_field_value = $rootScope.selected_field.value;
                    loadDataFunction();

                }
                else {
                    $rootScope.selected_field = {name: null, value: null};
                    if ($rootScope.sub_app_page_id == "weather_override") {
                        $khMessage.show("No field found for current farm selection", 4000);
                        $scope.chartConfiguration = false;
                    } else {
                        loadDataFunction();
                    }
                }
                $rootScope.update_growing_season_farm_field_session({
                    field: $rootScope.selected_field.value,
                    farm: $rootScope.selected_farm.value
                });

            });
        }
        else {
            $rootScope.selected_farm = {name: null, value: null};
            $rootScope.selected_field = {name: null, value: null};
            $khMessage.show("No farm found", 4000);
            $scope.weather_location_information = "No Farm/Field created.";
            $scope.weather_status = "";
            $scope.chartConfiguration = false;
        }
    }
});
