angular
  .module('WeatherApp')
  .controller('SensorListMainController', function(
    $scope,
    $rootScope,
    $timeout,
    $window,
    $mdDialog,
    $khPulse,
    $resource,
    $khMessage,
    leafletData,
    NgTableParams,
    PWSFactory,
    StoreLotFactory,
    LegacyOperationFactory,
    DateTimeHelperFactory,
    EADataResource,
    PlatformSettingsFactory,
    SatelliteProcessedDataListFactory,
    SatellitePublishedDatasetResource,
    FieldNamesFactory,
    KisanAuthTokenServiceFactory,
    PlatformSettingsService,
    PWSAssignFieldsDataFactory,
    WeatherUtilsService,
    GenericMapService,
    WeatherFunctionHelperService,
    GenericUtilsService,
    FarmFiltersService,
    ErrorLoggingService,
    SatelliteDetailDialogService,
    SelectedTeamService
  ) {
    $scope.show_hide_layer_transparency = false;
    $scope.sort_layer_by_date = true;
    $scope.toggleLayerOrder = function() {
      $scope.sort_layer_by_date = !$scope.sort_layer_by_date;
    };

    $scope.loadAssignedFields = function(gateway_slug) {
      PWSAssignFieldsDataFactory.get({ gateway_slug: gateway_slug }, function(
        apiData
      ) {
        $scope.assigned_fields = apiData.data;
      });
    };

    $scope.toggleLayerTransparency = function() {
      $scope.show_hide_layer_transparency = !$scope.show_hide_layer_transparency;
    };
    $scope.showSatelliteImages = false;
    var mapid = 'landingMap';

    let sub = SelectedTeamService.getTeamSlug$().subscribe(function(team) {
      if (team) {
        reloadData(team);
      }
    });

    $scope.$on('$destroy', function() {
      sub.dispose();
    });

    function updateStationDistances() {
      GenericMapService.calculateStationDistanceFromSelectedLocation(
        $rootScope.public_source_data_geoJson.features
      );
      $scope.EATableDataParameters = new NgTableParams(
        {},
        { dataset: $rootScope.public_source_data_geoJson.features }
      );

      GenericMapService.calculateStationDistanceFromSelectedLocation(
        $rootScope.pwsdata_geoJson.features
      );
      $scope.weatherStationTableParameters = new NgTableParams(
        {},
        { dataset: $rootScope.pwsdata_geoJson.features }
      );
    }

    $scope.$on('filter:selections_updated', function() {
      updateStationDistances();
    });

    PlatformSettingsService.promise.then(function(data) {
      $scope.platformSettings = data;
      $scope.default_plot_name_prefix = data.PLOT_NAME_PREFIX;
      $scope.default_plot_name_suffix = data.PLOT_NAME_SUFFIX;
      $scope.default_zoomlevel = data.DEFAULT_CENTER_ZOOM;
      $scope.default_latval = data.DEFAULT_CENTER_LAT;
      $scope.default_lngval = data.DEFAULT_CENTER_LONG;
      $scope.default_workable_area_contingency =
        data.DEFAULT_FIELD_WORKABLE_AREA_CONTINGENCY;
      if (typeof $scope.default_workable_area_contingency === 'undefined') {
        $scope.default_workable_area_contingency = 1;
      }
      $scope.planting_season_current = data.PLANTING_SEASON_CURRENT;
      $scope.khgeoserver = data.KISANSAT_UAVGEOSERVER_URL;
      $scope.ksatgeoserver = data.KISANSAT_SATGEOSERVER_URL;
      $scope.khauthserver = data.KH_INFRASTRUCTURE_AUTH_URL;

      $scope.khmapprintserver = data.KISANSAT_PRINTSERVER_URL;
      $scope.public_station_search_radius =
        data.WEATHER_PUBLIC_STATION_SEARCH_RADIUS;

      FarmFiltersService.setDefaultFieldFarm();
      GenericMapService.loadPublicSourceData(
        $khPulse,
        $rootScope,
        leafletData,
        mapid,
        EADataResource,
        { data_format: 'geojson' },
        $khMessage,
        NgTableParams,
        $scope
      );
      GenericMapService.loadPWSData(
        falseKHPulse,
        $rootScope,
        leafletData,
        mapid,
        PWSFactory,
        $khMessage,
        NgTableParams,
        $scope
      );
    });

    var initialPageSetup = function() {
      $rootScope.currentDataLoggerView = 'personalWeatherStation';
      WeatherFunctionHelperService.setUpSensorListSecondaryNavBarParameters(
        $rootScope
      );
    };

    initialPageSetup();

    $scope.sensorListMainCards = [
      {
        kh_card_content_template:
          '/static/assets/khapps/weather/sensor_list/SensorListMainCards.html',
        card_flex: 100,
        transparent_card: true
      }
    ];

    $rootScope.$on('changedSelectedFeature', function() {
      GenericMapService.changed_selection($rootScope, $scope);
    });

    $rootScope.$on('completedFieldLoading', function(object) {
      GenericMapService.setDefaultMapView($rootScope, $scope);
    });

    $rootScope.changeSensorListMainViews = function(selectedTabView) {
      $rootScope.currentDataLoggerView = selectedTabView;
    };

    $rootScope.showSatelliteDataSubscriptionButton = true;
    $rootScope.satelliteSubscription = function(objectFeature) {
      var subscriptionDict = {
        team: $rootScope.selected_team,
        field: {
          field_slug: objectFeature.properties.field_slug,
          type: 'FeatureCollection',
          features: [objectFeature]
        },
        subscription_duration: 12
      };
      $mdDialog.show({
        controller: 'SatelliteServiceSubscriptionController',
        templateUrl:
          '/static/assets/khapps/ksat/SatelliteServiceSubscriptionForm.html',
        parent: angular.element(document.body),
        animation: true,
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: false,
        locals: { subscription_dict: subscriptionDict }
      });
    };

    $rootScope.shareObject = function(object_type, object_slug) {
      $mdDialog.show({
        controller: 'ShareWithAddEditController',
        templateUrl:
          '/static/assets/khapps/profiles/share_with/partials/share_with_form.html',
        parent: angular.element(document.body),
        animation: true,
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: false,
        locals: { object_slug: object_slug, object_type: object_type }
      });
    };

    $rootScope.showNotificationButton = true;
    $rootScope.singleObjectNotification = function(
      objectType,
      objectSlug,
      objectName
    ) {
      $mdDialog.show({
        controller: 'UserNotificationPreferencesController',
        templateUrl:
          '/static/assets/khapps/user_notification_preferences/UserNotificationPreferences.html',
        parent: angular.element(document.body),
        locals: {
          configDict: {
            objectType: objectType,
            object_slug: objectSlug,
            name: objectName
          }
        },
        clickOutsideToClose: false
      });
    };

    $scope.$on('leftsidenav_toggled', function(event, filterValue) {
      $rootScope.reset_map_view($window, $timeout, leafletData, mapid);
    });

    $rootScope.pwsFilterCriteria = function(selected_station) {
      $rootScope.selected_station = selected_station;
    };

    GenericMapService.mapbased_filter_watchers($rootScope, leafletData, mapid);

    $scope.callFunction = function(name) {
      if (angular.isFunction($scope[name])) $scope[name]();
    };

    var layer;
    var mapSpeedDialList = [
      {
        isOpen: false,
        availableModes: ['md-fling', 'md-scale'],
        selectedMode: 'md-scale',
        selectedDirection: 'up',
        availableDirections: ['up', 'down', 'left', 'right'],
        menuIcon: 'plus',
        postion: 'bottom-right',
        submenuList: [
          {
            label: 'Weather Station',
            tooltip: {
              label: 'Add Weather Station',
              direction: 'left'
            },
            action: 'draw_weather_station',
            icon: 'routes'
          }
        ]
      }
    ];
    layer = GenericMapService.buildmap(
      $scope,
      $rootScope,
      leafletData,
      mapid,
      mapSpeedDialList
    );

    $rootScope.toggleBottomBar = function(objectFeature) {
      $scope.bbox = objectFeature.properties.bounds_calculated;
      $scope.toggleLayerTransparency();
      $scope.hideLayer();
      if ($scope.sidebar.isVisible()) {
        $scope.sidebar.toggle();
      }
      if (!$scope.bottombar.isVisible()) {
        $scope.bottombar.toggle();
      }
      $scope.bottombar_path =
        '/static/assets/khapps/fms_app/partials/mapSidebar/information_bottombar.html';
    };

    $scope.toggleSideBar = function() {
      if (!$scope.sidebar.isVisible()) {
        $scope.sidebar.toggle();
      }
      $scope.sidebar_path =
        '/static/assets/khapps/fms_app/partials/mapSidebar/information_sidebar.html';
    };

    $scope.toggleOverlayLayer = function(item) {
      $scope.layers.overlays[item.name].visible = !$scope.layers.overlays[
        item.name
      ].visible;
    };

    $scope.deselectAllLayers = function() {
      for (var key in $scope.layers.overlays) {
        $scope.layers.overlays[key].visible = false;
      }
    };

    $scope.removeAllOverlayLayers = function() {
      for (var key in $scope.layers.overlays) {
        delete $scope.layers.overlays[key];
      }
    };

    $scope.showSelectedLayer = function(weight_value, fill_opacity_value) {
      var highlightStyle = {
        weight: weight_value,
        opacity: 1,
        dashArray: 10,
        fillOpacity: fill_opacity_value
      };
      $rootScope.tempMarker.bringToFront();
      $rootScope.tempMarker.setStyle(highlightStyle);
    };

    $scope.orderLayerbyDate = function() {};

    $scope.orderLayerbyName = function() {};

    $scope.informationSatelliteLayer = function(
      field_slug,
      object_slug,
      process_name
    ) {
      SatelliteDetailDialogService.show(
        $scope,
        field_slug,
        object_slug,
        process_name
      );
    };

    $scope.deleteSatelliteLayer = function(object_slug) {
      var confirm = $mdDialog
        .confirm()
        .title($filter('translate')('common.are_you_sure_to_delete'))
        .ariaLabel($filter('translate')('common.delete'))
        .ok($filter('translate')('yes'))
        .cancel($filter('translate')('no'));
      $mdDialog.show(confirm).then(
        function() {
          SatellitePublishedDatasetResource.delete(
            { publish_slug: object_slug },
            function() {
              $khMessage.show(
                $filter('translate')('deleted_successfully'),
                5000
              );
            },
            function(error) {
              ErrorLoggingService.genericAPIError(error);
            }
          );
        },
        function() {}
      );
    };

    $scope.hideSelectedLayer = function(weight_value, fill_opacity_value) {
      var highlightStyle = {
        weight: weight_value,
        opacity: 1,
        dashArray: 10,
        fillOpacity: fill_opacity_value
      };
      $rootScope.tempMarker.bringToFront();
      $rootScope.tempMarker.setStyle(highlightStyle);
    };

    GenericMapService.respondToFeatureSelectionChange(
      mapid,
      $khPulse,
      $rootScope,
      $scope,
      LegacyOperationFactory,
      DateTimeHelperFactory,
      StoreLotFactory,
      null,
      EADataResource,
      SatelliteProcessedDataListFactory,
      SatellitePublishedDatasetResource
    );
    //Drawing on Map
    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    $scope.draw_weather_station = function() {
      $scope.weather_station_marker = true;
      add_marker();
    };

    $scope.draw_public_data_source = function() {
      $scope.public_data_source_marker = true;
      add_marker();
    };

    function add_marker() {
      leafletData.getMap(mapid).then(function(map) {
        var marker = new L.Draw.Marker(map, {});
        marker.enable();
      });
    }

    $rootScope.post_save_signal = function() {
      $scope.remove_drawn_layer();
      if ($rootScope.selectedFeature) {
        $rootScope.selectedFeature.edited = false;
      }
    };

    $scope.remove_drawn_layer = function() {
      if (layer) {
        $scope.drawnItems.removeLayer(layer);
      }
    };

    leafletData.getMap(mapid).then(function(map) {
      leafletData.getLayers().then(function(baselayers) {
        $scope.drawnItems = baselayers.overlays.draw;
        map.on('draw:created', function(e) {
          if (layer) {
            $scope.remove_drawn_layer();
          }
          var type = e.layerType;
          layer = e.layer;
          if (type === 'marker') {
            $rootScope.geocoder = {};
            GenericMapService.getMarkerGeocode($rootScope, layer, function() {
              if ($scope.weather_station_marker) {
                $rootScope.pws_data = {};
                $rootScope.pws_data['latitude'] = $rootScope.geocoder.latitude;
                $rootScope.pws_data['longitude'] =
                  $rootScope.geocoder.longitude;
                $rootScope.pws_data['team'] = $scope.selected_team_id;

                $mdDialog.show({
                  controller: 'AddEditPWSController',
                  templateUrl:
                    '/static/assets/khapps/weather/partials/forms/personalweatherstation/PWSForm.html',
                  parent: angular.element(document.body),
                  locals: {
                    gateway_slug: null
                  },
                  clickOutsideToClose: false,
                  escapeToClose: true,
                  onRemoving: function() {
                    $rootScope.post_save_signal();
                  }
                });
                $scope.drawnItems.addLayer(layer);
                $scope.weather_station_marker = false;
              } else if ($scope.public_data_source_marker) {
                $rootScope.public_source_data = {};
                $rootScope.public_source_data['latitude'] =
                  $rootScope.geocoder.latitude;
                $rootScope.public_source_data['longitude'] =
                  $rootScope.geocoder.longitude;
                $rootScope.public_source_data['team'] = $scope.selected_team_id;

                $scope.drawnItems.addLayer(layer);
                $scope.public_data_source_marker = false;
              }
            });
          }
        });
      });
    });

    $scope.edit_pws = function(gateway_slug) {
      $mdDialog.show({
        controller: 'AddEditPWSController',
        skipHide: true,
        multiple: true,
        templateUrl:
          '/static/assets/khapps/weather/partials/forms/personalweatherstation/PWSForm.html',
        locals: {
          gateway_slug: gateway_slug
        },
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: true
      });
    };

    $scope.showDetailsPDS = function(pds) {
      $rootScope.pds = pds;
      $mdDialog.show({
        controller: 'PublicDataSourceDetailController',
        skipHide: true,
        multiple: true,
        templateUrl:
          '/static/assets/khapps/weather/partials/public_data_sources/PDSDetail.html',
        locals: {
          pds: pds
        },
        clickOutsideToClose: true
      });
    };

    $scope.showDetailsOfLogger = function(feature) {
      $rootScope.gateway = feature;
      $mdDialog.show({
        controller: 'PWSDetailController',
        skipHide: true,
        multiple: true,
        templateUrl:
          '/static/assets/khapps/weather/partials/personalweatherstation/PWSDetail.html',
        locals: {
          gateway: feature
        },
        clickOutsideToClose: true
      });
    };

    $scope.editAssignedFields = function(gateway_slug, name) {
      $rootScope.selected_station_detail = {
        name: name,
        value: gateway_slug
      };
      $mdDialog.show({
        controller: 'AddEditPWSAssignedFieldsController',
        skipHide: true,
        multiple: true,
        templateUrl:
          '/static/assets/khapps/weather/partials/forms/personalweatherstation/EditAssignedFieldsForm.html',
        clickOutsideToClose: false
      });
    };
    $scope.enableStationWeatherDataExportButton = true;
    $scope.OpenStationExportDialog = function(gateway_slug) {
      var dataFromParentController = {};
      dataFromParentController['slug'] = gateway_slug;
      $mdDialog.show({
        controller: 'PWSDataExportController',
        skipHide: true,
        multiple: true,
        templateUrl:
          '/static/assets/khapps/weather/partials/abstract/WeatherDataExport.html',
        parent: angular.element(document.body),
        locals: {
          dataFromParentController: dataFromParentController
        },
        clickOutsideToClose: false
      });
    };

    $scope.cancelFeature = function() {
      $rootScope.editFeatureMode = false;
    };

    $scope.saveFeature = function() {
      $rootScope.editFeatureMode = false;
    };

    var falseKHPulse = {
      show: function show() {},
      hide: function hide() {}
    };

    function reloadData(filter) {
      FarmFiltersService.setDefaultFieldFarm();
      fetchFields(filter.slug);
      fetchFarms(filter.slug);
    }
    function fetchFields(team_slug) {
      $resource('/api/fms/geo/field/').get({ team_slug: team_slug }, result => {
        $rootScope.fielddata_geoJson = result;
        leafletData.getMap(mapid).then(function(map) {
          if ($rootScope.fielddata_geoJsonLayer) {
            map.removeLayer($rootScope.fielddata_geoJsonLayer);
          }
          $rootScope.fielddata_geoJsonLayer = GenericMapService.createGeoJsonPolygonLayer(
            result,
            $rootScope,
            leafletData,
            mapid
          );
          $rootScope.leaflet_field_layer_id =
            $rootScope.fielddata_geoJsonLayer._leaflet_id;

          map.addLayer($rootScope.fielddata_geoJsonLayer);
          $rootScope.fielddata_geoJsonLayer.bringToBack();
        });
        updateStationDistances();
      });
    }

    function fetchFarms(team_slug) {
      $resource('/api/fms/geo/farm/').get({ team_slug: team_slug }, result => {
        $rootScope.farmdata_geoJson = result;
        leafletData.getMap(mapid).then(function(map) {
          if ($rootScope.farmdata_geoJsonLayer) {
            map.removeLayer($rootScope.farmdata_geoJsonLayer);
            map.removeLayer($rootScope.farmdata_clusterLayer);
          }
          $rootScope.farmdata_geoJsonLayer = GenericMapService.createGeoJsonFarmLayer(
            result,
            $rootScope,
            leafletData,
            mapid
          );
          $rootScope.farmdata_clusterLayer = GenericMapService.createFarmMarkerClusterLayer(
            $rootScope.farmdata_geoJsonLayer,
            leafletData,
            mapid
          );
        });
        updateStationDistances();
      });
    }

    $scope.$on('leafletDirectiveMap.landingMap.zoomanim', function(
      event,
      args
    ) {
      GenericMapService.display_field_labels(
        $scope,
        mapid,
        leafletData,
        $rootScope
      );
    });

    $scope.$on('leafletDirectiveMap.landingMap.moveend', function(event, args) {
      GenericMapService.display_field_labels(
        $scope,
        mapid,
        leafletData,
        $rootScope
      );
    });

    $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
    $scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();
    $scope.parameterUnitMapWithoutBracket = WeatherUtilsService.parameterUnitMapWithoutBracket();

    $rootScope.viewDetailWeather = function(current_date) {
      var dialogTitle = 'Weather: ';
      var object_type, object_slug;

      if (
        angular.isDefined(
          $rootScope.selectedFeature.feature.properties.field_slug
        )
      ) {
        dialogTitle =
          dialogTitle +
          $rootScope.selectedFeature.feature.properties.field_name;
        object_type = 'field';
        object_slug = $rootScope.selectedFeature.feature.properties.field_slug;
      } else {
        dialogTitle =
          dialogTitle + $rootScope.selectedFeature.feature.properties.farm_name;
        object_type = 'farm';
        object_slug = $rootScope.selectedFeature.feature.properties.farm_slug;
      }
      $mdDialog.show({
        controller: 'DetailWeatherViewController',
        templateUrl:
          '/static/assets/khapps/weather/apps/detail_weather_view/DetailWeatherView.html',
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: false,
        locals: {
          dialogTitle: dialogTitle,
          object_slug: object_slug,
          object_type: object_type
        }
      });
    };
  });
