angular.module('WeatherApp').config([
  '$routeProvider',
  '$resourceProvider',
  function($routeProvider, $resourceProvider) {
    $resourceProvider.defaults.stripTrailingSlashes = false;
    $routeProvider

      .when('/', {
        name: 'Weather Landing',
        controller: 'WeatherLandingController',
        templateUrl:
          '/static/assets/khapps/weather/partials/WeatherLanding.html'
      })

      .when('/sensorlist/', {
        name: 'Sensor List',
        controller: 'SensorListMainController',
        templateUrl:
          '/static/assets/khapps/weather/sensor_list/SensorListMain.html'
      })
      .when('/regionalclimatesummary/', {
        name: 'Regional Climate Summary',
        controller: 'RegionalClimateSummaryController',
        templateUrl:
          '/static/assets/khapps/weather/partials/regionalclimatesummary/RegionalClimateSummary.html'
      })
      .when('/override/', {
        name: 'Weather Override',
        controller: 'WeatherOverrideController',
        templateUrl:
          '/static/assets/khapps/weather/partials/weatheroverride/WeatherOverride.html'
      })

      .otherwise({
        redirectTo: '/'
      });
  }
]);
